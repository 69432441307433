import styled from "styled-components";
import Box from "../Box";
export const StyledSessionCard = styled(Box)`

  width: 600px;
  overflow: hidden;
  color:white;
  background-color : unset;
 

  .checkbox{
    gap : 1rem
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 100px #1b1717 inset !important;
    -webkit-text-fill-color: white !important;
    caret-color : white;
  }

  .content {
    padding: 2rem 1.75rem 0px;
  }

  .flex-gap{
    gap:0.5rem;
  }

  .sub-title
    font-weight:200;
  }

  .login-title{
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-top: 0px;
    color: white;
  }

  .login-button{
    border: 1px solid rgb(229, 229, 229);
    background-color: black;
    font-size: 19px;
    margin : auto;
    min-width: 100px;
    text-transform : none;
    font-family:inherit;
  }

  .login-button:hover { 
    background-color : silver;
    color : black;
  }
  

  @media screen and (min-width: 1980px) {
    width : 80%;
    font-size :1vw;
    min-height : 55vh;
    
    .forgot-psw{
      width : 50%;
      margin:auto;
    }

    .resetpass-form{
      width : 50%;
    }

    h1{
      font-size : 2vw;
    }

    .checkbox > input{
      width:1vw;
      height:1vw;
    }

    .login-title{
      font-size : 2vw;
    }

    .input-field{
      width : 50%;
      margin : auto;
      margin-bottom : 1vw;
    }

    input{
      height:3vw;
    }

    label{
      font-size:0.4vw;
      ;
    }

    .end-adornment > div{
      
    }

    .login-button{
      font-size : 1vw;
      height:unset;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    .content {
      padding: 1.5rem 1rem 0px;
    }
  }

  @media screen and (max-height: 650px) {
    margin-top : 0;

    .checkbox input {
      height : 15px;
    }

    form{
      padding-top : 0!important;
    }

    input{
      height : 50px;
    }
    
  }
`


