import styled from "styled-components";
import TableContainer from '@mui/material/TableContainer';
import Button from "../buttons/Button";
import FlexBox from "../FlexBox";
import { Link } from "@inertiajs/react";
import { deviceSize } from "../../utils/constants";

const StyledBasicTable = styled(TableContainer)`

    font-family : inherit!important;
    border : solid 1px #707070;

    .table-price{
        white-space : nowrap ;
        padding-right : 5px!important;
    }
    
    .MuiTableCell-root{
        padding : 12px;
        border-bottom-color : #707070;
        color : inherit;
        font-family : inherit;
        font-weight : 200;
    }

    @media only screen and (max-width: 1526px) {
        .MuiTableCell-root{
            padding : 8px;
            font-size : 1vw;
        }
    }

    @media only screen and (max-width: 1100px) {
        .MuiTableCell-root{
            padding : 0px;
            padding-top: 5px;
            padding-bottom:5px;
            font-size : 12px;
            text-align : center;
        }        
    }

    @media only screen and (min-width: ${deviceSize.xlg}px) {
        .MuiTableCell-root{
            font-size:0.8vw;
            padding : 0.6vw;
        }

        
    }

    @media only screen and (max-width: 1100px) {
        .MuiTable-root{
           table-layout:fixed;
         }
    }

    @media only screen and (max-width: 420px) {

        margin-top:1rem;

        .MuiTableBody-root .MuiTableCell-root{
            font-size:12px;
        }
    }

    @media only screen and (max-width: ${deviceSize.xs}px) {


        .MuiTable-root{
            table-layout : unset;
        } 

        .MuiTableBody-root .MuiTableCell-root{
            font-size:11px;
        }

    }

`

export const StyledQuantityButtons = styled(FlexBox)`

    height : 100%;
    gap : 5px;

    svg{
        color : white !important;
    }

    .quantity-button{
        padding : 1px;
        border : solid 2px white;
        border-radius: 8px ;
        width:30px;
    }

    .quantity{
        border: 2px solid white;
        border-radius: 8px;
        font-weight: 500;
        font-size : 15px;
        text-align: center;
        color: white;
        width: 50px;
        min-width:25px;
    }

    @media only screen and (min-width: 1980px) {
        gap : 0.4vw;
        button{
            width:1.2vw;
            border-radius : 10px;
        }
        .quantity{
            font-size : 0.8vw;
            border-radius : 10px;
            width : 2vw;
        }
    }

    @media only screen and (max-width : 440px){
        .quantity{
            width : 25px;
        }

        .quantity-button{
            width : 35px;
            border-radius : 7px;
        }
    }
`

export const StyledAddButton = styled(Button)`


@media only screen and (min-width : 1980px){
    padding : 0.5vw;

    svg{
        width : 1.3vw;
    }
}

.test{
    transition : 0.4s ease;
}

.start{
    transition : 1s ease;
    position: absolute;
    right: 20%;
    transform : translateX(-20px);
    path{
        fill : transparent;
    }
    
}

.end{
    right : 0%;
    transition : 1s ease;
    transform : translateX(15px);
    right:25;
    path {
        fill : #2e7d32;
    }
}

`

export const StyledLink = styled(Link)`
height : 40px;
position:relative;
display: flex;
align-items: center;
gap : 2rem;
margin-bottom : 2rem;
border-radius : 5px;
background: linear-gradient(120deg, rgba(0, 0, 0, 0.8) 80%, #e50606 80%);
background-size: 100% 100%;
color: white;
padding: 5px 20px;
text-decoration: none;
transition: background-position 0.3s;
width : max-content;

:after{
    position: absolute;
    content: "";
    top: 0;
    right:0;
    width: 0;
    height: 100%;
    border-radius : 5px;
    background: rgb(229, 6, 6);
    transition: ease .35s;
  }

:hover:after{
    width: 100%;
}

  span , img {
    z-index : 1;
  }

  img{
    height : auto;
    max-width : 30px;
    max-height : 30px;
  }

  @media only screen and (min-width : ${deviceSize.xlg}px){
    img{
        max-width: unset;
        max-height: unset;
        width: 1.1vw;
    }
  }

  @media only screen and (max-width : ${deviceSize.sm}px){
    gap : 1rem;
    img{
        width : 25px;
    }
    padding-right: 5px;
    padding-left: 5px;
    
    span{
        font-size : 12px;
    }
    
  }

`

export default StyledBasicTable