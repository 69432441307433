import { cartActionType, cartInitialState, cartReducer } from "./cartReducer";
import { authActionType, authInitialState, authReducer } from "./authReducer";
import { currencyActionType, currencyInitialState, currencyReducer } from "./currencyReducer";
import combineReducers from "./combineReducers";
import {
  layoutActionType,
  layoutInitialState,
  layoutReducer,
} from "./layoutReducer";

export type rootActionType = layoutActionType | cartActionType | authActionType | currencyActionType;

export const initialState = {
  layout: layoutInitialState,
  cart: cartInitialState,
  auth: authInitialState,
  currency: currencyInitialState
}

export const rootReducer = combineReducers({
  layout: layoutReducer,
  cart: cartReducer,
  auth: authReducer,
  currency: currencyReducer,
})
