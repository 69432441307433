import { useEffect, useState, useRef } from "react";
import Avatar from "..//avatar/Avatar";
import FlexBox from "..//FlexBox";
import { useAppContext } from "../../contexts/app/AppContext";
import { CartItem } from "@reducer/cartReducer";
import { Link } from '@inertiajs/react';;
import React, { Fragment, useCallback } from "react";
import Button from "../buttons/Button";
import Divider from "../Divider";
import Icon from "../icon/Icon";
import Typography, { H2, H3, H5, Paragraph, Span, Tiny } from "../Typography";
import { StyledMiniCart } from "./MiniCartStyle";
import useWindowSize from "../../hooks/useWindowSize";
import cartImage from './cart.svg';
import checkImage from './check.svg';
import Box from "../Box";
import Image from "../Image";
import CartService from "../../services/frontend/cart.service";
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDelete from "./ConfirmDelete";
import { AnimatePresence, motion, useIsPresent } from "framer-motion";
import { ViewportList } from "react-viewport-list";
import List from 'rc-virtual-list';
import useWindowHeight from "../../hooks/useWindowHeight";
import { colors, primaryColor } from "../../utils/themeColors";
import { useTranslation } from "react-i18next";

// import { FixedSizeList as List } from 'react-window';
//import AutoSizer from 'react-virtualized-auto-sizer';

type MiniCartProps = {
  toggleSidenav?: () => void;
};

const MiniCart: React.memo<MiniCartProps> = ({ toggleSidenav }) => {

  const { state, dispatch } = useAppContext();
  const { cartList } = state.cart;
  const { currencySymbol } = state.currency;
  const { t } = useTranslation()
  const screenWidth = useWindowSize()
  const screenHeight = useWindowHeight()
  const { setOpenSnackCart, setOpenSnackRemove, setOpenSnackUpdate, setUpdateQ } = useAppContext()
  const cartService = new CartService({
    setOpenSnackCart: setOpenSnackCart,
    setOpenSnackRemove: setOpenSnackRemove,
    setOpenSnackUpdate: setOpenSnackUpdate,
  })

  const [open, setOpen] = useState(false)
  const [product, setProduct] = useState()

  const rowCount = 5000;
  const listHeight = 400;
  const rowHeight = 50;
  const rowWidth = 700;
  const ref = useRef(null);
  const handleCartAmountChange = (amount, product) => {


    //console.log(product);

    let payload = {
      ...product,
      qty: amount,
    }

    //console.log('payload => ', payload)

    cartService.update(dispatch, payload, setUpdateQ)
    setOpen(false)

  }

  const handleClickOpen = (product) => {
    setProduct(product)
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getTotalPrice = () => {
    return (
      cartList.reduce(

        (accumulator, item) => accumulator + parseFloat(item.price_with_discount ? item.price_with_discount : item.price) * item.qty,
        0
      ) || 0
    );
  };

  const getstep = (step: string) => {
    if (step) {
      return parseInt(step)
    } else return 1
  }

  const sizeOrder = {
    S: 1,
    M: 2,
    L: 3,
    XL: 4,
    '2XL': 5,
    '3XL': 6,
    '4XL': 7,
  };



  /*cartList.sort((a, b ) => {

    const timestampA = new Date(a?.created_at).getTime()
    const timestampB = new Date(b?.created_at).getTime()

    if (timestampA !== timestampB) {
      //return timestampB - timestampA
    }


    if (a?.additional.product_id !== b?.additional.product_id) {
      return a?.product_id - b?.product_id
    }

    if (a?.additional.attributes.color?.option_id !== b?.additional.attributes.color?.option_id) {
      return a?.additional.attributes.color?.option_id - b?.additional.attributes.color?.option_id
    }

    const sizeA = a?.additional.attributes?.size?.option_label
    const sizeB = b?.additional.attributes?.size?.option_label

    const sizeOrderA = sizeOrder[sizeA] || 0
    const sizeOrderB = sizeOrder[sizeB] || 0
    if (sizeOrderA !== sizeOrderB) {
      return sizeOrderA - sizeOrderB
    }

  })*/

  /*cartList.sort((a, b ) => {

    const timestampA = new Date(a?.created_at).getTime()
    const timestampB = new Date(b?.created_at).getTime()

    const sizeA = a?.additional.attributes?.size?.option_label
    const sizeB = b?.additional.attributes?.size?.option_label

    const sizeOrderA = sizeOrder[sizeA] || 0
    const sizeOrderB = sizeOrder[sizeB] || 0


    return ( (a?.additional?.product_id - b?.additional?.product_id && timestampB - timestampA) || a?.additional.attributes.color?.option_id - b?.additional.attributes.color?.option_id  || sizeOrderA - sizeOrderB  )


  
  });*/


  return (
    <StyledMiniCart>

      <ConfirmDelete item={product} open={open} setOpen={setOpen} handleCartAmountChange={handleCartAmountChange} handleClose={handleClose} handleOpen={handleClickOpen} />

      <Box className="cart-list">
        {!!!cartList.length && (
          <FlexBox
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          //height="calc(100% - 80px)"
          >

            <H3
              mt="1rem"
              color="text.muted"
              textAlign="center"
              maxWidth="80%"
            >
              {t("minicart.empty")}
            </H3>
          </FlexBox>
        )}
        <AnimatePresence>
          <div className="scroll-container" ref={ref}>

            {/*<List style={{ transition: '1s ease' }} data={cartList} height={screenHeight - 200} itemHeight={100} itemKey="id">*/}
            <List style={{ transition: '1s ease' }} data={cartList} itemHeight={100} itemKey="id">
              {(item, ind) =>
                <motion.div
                  key={item.id}
                  initial={ind < 6 ? { opacity: 0, height: 0 } : undefined}
                  animate={ind < 6 ? { opacity: 1, height: "auto" } : undefined}
                  exit={ind < 6 ? { opacity: 0, height: 0 } : undefined}
                  transition={ind < 6 ? {
                    opacity: { duration: 1.5 },
                    height: { duration: 0.3 },
                  } : undefined}
                  layout
                >

                  <Box className="cart-item">
                    <Link href={'/' + item.url_key}>
                      <Avatar
                        src={item.imgUrl}
                        mx="1rem"
                        alt={item.name}

                      />
                    </Link>

                    <FlexBox flexBasis={'80%'} flexDirection="column" alignItems={'flex-start'}
                      style={screenWidth > 786 ? { gap: 30 } : { gap: 10 }}>
                      <Box className="product-details">
                        <Link href={'/' + item.url_key}>
                          <H5 fontWeight={600} fontSize={screenWidth <= 786 ? 13 : 'unset'}>
                            {item.name}
                          </H5>
                        </Link>
                        <FlexBox className="attr"><Span fontSize={screenWidth <= 786 ? 13 : 'unset'}>{t("color")}</Span ><Span fontSize={screenWidth <= 786 ? 13 : 'unset'}>{item.additional?.attributes?.color?.option_label}</Span></FlexBox>

                        {!item.additional.attributes.packages &&
                          <FlexBox className="attr">
                            <Span fontSize={screenWidth <= 786 ? 13 : 'unset'} flexBasis={'40%'} >{t("size")}</Span>
                            {item?.additional?.attributes?.megethoscup?.option_label !== undefined && <Span fontSize={screenWidth <= 786 ? 13 : 'unset'}>{item?.additional?.attributes?.megethoscup?.option_label}</Span>}
                            {item?.additional?.attributes?.size?.option_label !== undefined && <Span fontSize={screenWidth <= 786 ? 13 : 'unset'}>{item?.additional?.attributes?.size?.option_label}</Span>}
                            {item?.additional?.attributes?.megethoskaltsa?.option_label !== undefined && <Span fontSize={screenWidth <= 786 ? 13 : 'unset'}>{item?.additional?.attributes?.megethoskaltsa?.option_label}</Span>}
                          </FlexBox>
                        }
                        {item?.additional?.attributes?.packages?.option_label &&
                          <FlexBox className="attr">
                            <Span flexBasis={'40%'} >{t("size")}</Span>
                            <Span fontWeight='bold'>{item.additional.attributes.packages?.option_label} τεμ.</Span>
                          </FlexBox>
                        }

                        {item?.sku && <Span fontSize={screenWidth > 786 ? 15 : 12} fontWeight={200}>
                          #{item.sku}
                        </Span>}

                      </Box>
                      <FlexBox height={screenWidth < 1980 ? 24 : '1vw;'} style={{ gap: 5 }} position='relative' width='100%'>
                        <Button
                          variant="outlined"
                          color="yellow"
                          padding="1px"
                          size="none"
                          border='solid 2px'
                          borderColor="white"
                          borderRadius="8px"
                          onClick={() => handleCartAmountChange(item.qty - getstep(item.qty_step), item)}
                          className='quantity-button'
                        >
                          <Icon variant="small">minus</Icon>
                        </Button>

                        <Typography className='quantity'>
                          {item.qty}
                        </Typography>
                        <Button
                          variant="outlined"
                          color="yellow"
                          padding="5px"
                          size="none"
                          border='solid 2px'
                          borderColor="white"
                          borderRadius="8px"
                          onClick={() => handleCartAmountChange(item.qty + getstep(item.qty_step), item)}
                          className='quantity-button'
                        >
                          <Icon variant="small">plus</Icon>
                        </Button>

                      </FlexBox>

                    </FlexBox>

                    <Typography
                      fontWeight={600}
                      fontSize="14px"
                      color="white"
                      mt="4px"
                      className='price'
                    >
                      {currencySymbol + (parseFloat(item.price_with_discount ? item.price_with_discount : item.price) * item.qty).toFixed(2)}
                    </Typography>
                    <DeleteIcon sx={{ width: '1.3em', height: '1.3em' }} className="delete-icon" onClick={() => handleClickOpen(item)} color='info' fontSize="small" />


                  </Box>
                  <Divider />
                </motion.div>
              }
            </List>

            {/* {cartList.map((item , ind) =>
              <motion.div
                key={item.id}
                initial={ind < 6 ? { opacity: 0, height: 0 } : undefined}
                animate={ind < 6 ? { opacity: 1, height: "auto" } : undefined}
                exit={ind < 6 ? { opacity: 0, height: 0 } : undefined}
                transition={ind < 6 ? {
                  opacity: { duration: 1.5 },
                  height: { duration: 0.3 },
                } : undefined}
              >

                <Box className="cart-item">
                  <Link>
                    <Avatar
                      src={item.imgUrl}
                      mx="1rem"
                      alt={item.name}

                    />
                  </Link>

                  <FlexBox flexBasis={'80%'} flexDirection="column" alignItems={'flex-start'}
                    style={screenWidth > 786 ? { gap: 30 } : { gap: 10 }}>
                    <Box className="product-details">
                      <Link href={'/' + item.url_key}>
                        <H5 fontWeight={600} fontSize={screenWidth <= 786 ? 13 : 'unset'}>
                          {item.name}
                        </H5>
                      </Link>
                      <FlexBox className="attr"><Span fontSize={screenWidth <= 786 ? 13 : 'unset'}>Χρώμα:</Span ><Span fontSize={screenWidth <= 786 ? 13 : 'unset'}>{item.additional?.attributes?.color?.option_label}</Span></FlexBox>

                      {!item.additional.attributes.packages &&
                        <FlexBox className="attr">
                          <Span fontSize={screenWidth <= 786 ? 13 : 'unset'} flexBasis={'40%'} >Μέγεθος :</Span>
                          {item?.additional?.attributes?.size?.option_label !== undefined && <Span fontSize={screenWidth <= 786 ? 13 : 'unset'}>{item?.additional?.attributes?.size?.option_label}</Span>}
                          {item?.additional?.attributes?.megethoskaltsa?.option_label !== undefined && <Span fontSize={screenWidth <= 786 ? 13 : 'unset'}>{item?.additional?.attributes?.megethoskaltsa?.option_label}</Span>}
                        </FlexBox>
                      }
                      {item?.additional?.attributes?.packages?.option_label &&
                        <FlexBox className="attr">
                          <Span flexBasis={'40%'} >Μέγεθος :</Span>
                          <Span fontWeight='bold'>{item.additional.attributes.packages?.option_label} τεμ.</Span>
                        </FlexBox>
                      }

                      {item?.sku && <Span fontSize={screenWidth > 786 ? 15 : 12} fontWeight={200}>
                        #{item.sku}
                      </Span>}

                    </Box>
                    <FlexBox height={screenWidth < 1980 ? 24 : '1vw;'} style={{ gap: 5 }} position='relative' width='100%'>
                      <Button
                        variant="outlined"
                        color="yellow"
                        padding="1px"
                        size="none"
                        border='solid 2px'
                        borderColor="white"
                        borderRadius="8px"
                        onClick={() => handleCartAmountChange(item.qty - getstep(item.qty_step), item)}
                        className='quantity-button'
                      >
                        <Icon variant="small">minus</Icon>
                      </Button>

                      <Typography className='quantity'>
                        {item.qty}
                      </Typography>
                      <Button
                        variant="outlined"
                        color="yellow"
                        padding="5px"
                        size="none"
                        border='solid 2px'
                        borderColor="white"
                        borderRadius="8px"
                        onClick={() => handleCartAmountChange(item.qty + getstep(item.qty_step), item)}
                        className='quantity-button'
                      >
                        <Icon variant="small">plus</Icon>
                      </Button>

                    </FlexBox>

                  </FlexBox>

                  <Typography
                    fontWeight={600}
                    fontSize="14px"
                    color="white"
                    mt="4px"
                    className='price'
                  >
                    {currencySymbol + (parseFloat(item.price_with_discount ? item.price_with_discount : item.price) * item.qty).toFixed(2)}
                  </Typography>
                  <DeleteIcon sx={{ width: '1.3em', height: '1.3em' }} className="delete-icon" onClick={() => handleClickOpen(item)} color='info' fontSize="small" />


                </Box>
                <Divider />
              </motion.div>
            )} */}

          </div>
        </AnimatePresence>
      </Box>

      {cartList?.length > 0 &&
        <FlexBox className='final-price' p={'2rem'} justifyContent='space-between'>
          <Span fontWeight={600} >{t("total")}</Span>
          <Span fontWeight={600}>{currencySymbol + getTotalPrice().toFixed(2)}</Span>
        </FlexBox>}

      {cartList.length ? (
        <>
          <Link href="/checkout/cart">
            <Button
              variant="outlined"
              color="white"
              className='button-minicart-cart'
              onClick={toggleSidenav}

            >
              <Box display="flex" alignItems="center">
                <Typography className='button-text' fontWeight={600} fontSize={screenWidth > 768 ? 18 : 16} mr={2}>
                 {t("buttons.viewcart")}
                </Typography>
                {/* <Image width={screenWidth > 1920 ? '1vw' : ''} ml={10} src={cartImage}></Image> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20">
                  <g id="Group_253" data-name="Group 253" transform="translate(-91.994 -2.75)">
                    <path id="Path_21382" data-name="Path 21382" d="M730.707,286.707a1,1,0,0,0-1.414-1.414l-6,6a1,1,0,0,0,1.414,1.414Z" transform="translate(-628.006 -282.25)" fill={colors.primary.royal} />
                    <path id="Path_21383" data-name="Path 21383" d="M734.707,285.293a1,1,0,0,0-1.414,1.414l6,6a1,1,0,1,0,1.414-1.414Z" transform="translate(-628.006 -282.25)" fill={colors.primary.royal} />
                    <path id="Path_21384_-_Outline" data-name="Path 21384 - Outline" d="M721,291h22a1,1,0,0,1,0,2h-1.111c-.109.949-.2,1.825-.28,2.631l-.032.316c-.085.847-.173,1.724-.267,2.481a10.828,10.828,0,0,1-1.048,4.006,4.483,4.483,0,0,1-2.986,2.135,32.481,32.481,0,0,1-10.551,0,4.483,4.483,0,0,1-2.986-2.135,10.83,10.83,0,0,1-1.048-4.007c-.095-.761-.182-1.636-.267-2.483l-.032-.313c-.081-.806-.171-1.683-.28-2.632H721a1,1,0,0,1,0-2Zm2.005,1h0l.1.886c.079.689.148,1.34.211,1.953-.059-.571-.117-1.126-.185-1.726ZM743,292h-2.005l-.126,1.112c-.068.606-.128,1.166-.187,1.742.063-.618.133-1.273.213-1.969L741,292Zm-3.125,1h-15.75c.1.844.173,1.6.257,2.43l.035.342c.095.942.174,1.719.259,2.41a9.074,9.074,0,0,0,.8,3.26,2.148,2.148,0,0,0,.638.713,3.417,3.417,0,0,0,1.118.479,31.283,31.283,0,0,0,9.539,0,3.417,3.417,0,0,0,1.118-.48,2.148,2.148,0,0,0,.638-.713,9.074,9.074,0,0,0,.8-3.26c.085-.688.161-1.434.259-2.41h0l0-.029.032-.312C739.7,294.6,739.78,293.844,739.875,293Zm.621,3.69c.029-.281.057-.564.085-.844l.032-.316.03-.293-.03.293-.032.316C740.551,296.147,740.523,296.427,740.5,296.69Zm-17.08-.871-.029-.288-.027-.266.027.266.03.29Zm16.693,3.885c.057-.312.106-.632.15-.953C740.211,299.1,740.162,299.415,740.109,299.7Zm-16.22-.007c-.052-.285-.1-.6-.148-.939C723.785,299.074,723.833,299.389,723.89,299.7Zm14.828,3.076a3.123,3.123,0,0,0,.583-.682A3.149,3.149,0,0,1,738.717,302.772Zm-13.414.018a3.139,3.139,0,0,1-.594-.684A3.112,3.112,0,0,0,725.3,302.791Z" transform="translate(-628.006 -282.25)" fill="#e5e5e5" />
                    <path id="Path_21385" data-name="Path 21385" d="M730,295a1,1,0,0,0-1,1v4a1,1,0,0,0,2,0v-4A1,1,0,0,0,730,295Z" transform="translate(-628.006 -282.25)" fill={colors.primary.royal} />
                    <path id="Path_21386" data-name="Path 21386" d="M734,295a1,1,0,0,0-1,1v4a1,1,0,0,0,2,0v-4A1,1,0,0,0,734,295Z" transform="translate(-628.006 -282.25)" fill={colors.primary.royal} />
                  </g>
                </svg>
              </Box>
            </Button>
          </Link>

          <Link href="/checkout/onepage">
            <Button
              color="white"
              variant="outlined"
              className='button-minicart-order'
              onClick={toggleSidenav}
            >
              <Box display="flex" alignItems="center" style={{ whiteSpace: 'nowrap' }} >
                <Typography className='button-text' fontWeight={600} fontSize={screenWidth > 768 ? 20 : 18} mr={2}> {t("buttons.completeorder")}</Typography>
                <svg xmlns="http://www.w3.org/2000/svg" width="21.453" height="21.453" viewBox="0 0 21.453 21.453">
                  <g id="Group_252" data-name="Group 252" transform="translate(-91.994 -1.773)">
                    <path id="Path_21798" data-name="Path 21798" d="M1023.732,788.526a.9.9,0,1,0-1.269-1.264l-3.835,3.837-1.6-1.6a.9.9,0,0,0-1.269,1.264l2.235,2.235a.9.9,0,0,0,1.269,0Z" transform="translate(-916.801 -778.075)" fill={colors.primary.royal} />
                    <path id="Path_21799" data-name="Path 21799" d="M1008,789.727c0,8.833,1.9,10.727,10.727,10.727s10.727-1.893,10.727-10.727S1027.558,779,1018.727,779,1008,780.893,1008,789.727Zm1.788,0a23.731,23.731,0,0,0,.393,5.015,3.942,3.942,0,0,0,3.531,3.533,32.308,32.308,0,0,0,10.029,0,3.942,3.942,0,0,0,3.531-3.533,32.175,32.175,0,0,0,0-10.029,3.942,3.942,0,0,0-3.531-3.533,32.308,32.308,0,0,0-10.029,0,3.942,3.942,0,0,0-3.531,3.533A23.731,23.731,0,0,0,1009.788,789.727Z" transform="translate(-916.006 -777.227)" fill="#fff" fill-rule="evenodd" />
                  </g>
                </svg>
              </Box>
            </Button>
          </Link>
        </>
      ) : null}
    </StyledMiniCart>
  );
};

MiniCart.defaultProps = {
  toggleSidenav: () => { },
};

export default MiniCart;
