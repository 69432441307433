import systemCss from "@styled-system/css";
import { ReactSVG } from "react-svg";
import styled from "styled-components";
import { color, compose, space, variant } from "styled-system";
import { IconProps } from "./Icon";

const StyledIcon = styled(ReactSVG)<IconProps>(
  ({ color, size, transform, defaultcolor }) =>
    systemCss({
      svg: {
        width: "100%",
        height: "100%",
        color: 'white',
        transform,

        path: {
          fill: color ? `${color}.main` : defaultcolor,
        },
        polyline: {
          color: color ? `${color}.main` : defaultcolor,
        },
        polygon: {
          color: color ? `${color}.main` : defaultcolor,
        },
      },

      div: {
        display: "flex",
        width: size,
        height: size,
      },
    }),
  ({ size }) =>
    variant({
      prop: "variant",
      variants: {
        small: {
          div: {
            width: size || "1.25rem",
            height: size || "1.25rem",
            "@media only screen and (max-width: 450px)": {
              width: "1rem",
              height: "1rem",
            }
          },
          
        },
        medium: {
          div: {
            width: size || "1.5rem",
            height: size || "1.5rem",
            "@media only screen and (min-width: 1980px)": {
              width: "1.5vw",
              height: "1.5vw",
            }
          },
        },
        large: {
          div: {
            width: size || "2rem",
            height: size || "2rem",
            "@media only screen and (min-width: 1980px)": {
              width: "2vw",
              height: "2vw",
            }
          },
        },
        xlarge: {
          div: {
            width: size || '1vw',
            height: size || '1vw',

          },
        },
      },
    }),
  compose(color, space)
);

export default StyledIcon;
