import styled from "styled-components";
import { getTheme } from "../../utils/utils";

export const StyledMiniCart = styled.div`
  display: flex;
  flex-direction: column;
  height: 95dvh; 
  padding-top : 5rem;

  .rc-virtual-list{
    transition : unset!important;
  }
  
  .attr{
    gap : 10px;
 }

  .quantity-button{
    width:30px;
  }

  .quantity{
    border: 2px solid white;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    color: white;
    width: 50px;
    display: flex;
    justify-content: center;
    /* vertical-align: -webkit-baseline-middle; */
    align-items: center;
  }

  .delete-icon{

    position : absolute;
    right : 0%;
    bottom : 25%;
    transition : 0.25s ease;
    cursor : pointer;

    path{
      fill : white;

      &:hover{
        fill : #b95757;
      }
    }

    &:hover{
      
      transform : scale(1.1);
    }
  }
  

  .cart-list {
    //flex: 1 1 0;
    overflow : auto;
  }

  .cart-item > img {
    height : auto;
  }

  svg path {
    //fill : white;
  }

  .cart-item {
    display: flex;
    align-items: center;
    padding: 0px;
    width : 90%;
    position:relative;

    .add-cart {
      text-align: center;
    }

    .product-image {
      border-radius: 5px;
      margin-left: 1rem;
      margin-right: 1rem;
      width: 64px;
      height: 64px;
    }

    .product-details {
      flex: 1 1 0;
      min-width: 0px;

      .title {
        margin: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1;
      }
    }
    .clear-icon {
      color: ${getTheme("colors.gray.600")};
      cursor: pointer;
    }
  }

  .button-minicart-cart{
    width: 50%;
    margin: auto auto 1.5rem;
  }

  .button-minicart-order{
    width: 70%;
    margin: auto auto 1.5rem;
  }

  @media only screen and (min-width : 1980px){
    .image-holder {
      width:6vw;
      height:11vw;
    }

    .product-details h5 {
      font-size : 1vw;
    }

    .product-details span{
      font-size : 0.6vw;
    }

    .quantity-button {
      width:1.3vw;
      padding : 0px;
    }

    .quantity{
      font-size : 0.6vw;
    }

    .price{
      font-size :0.8vw;
    }

    .button-minicart-cart{
      height:auto;
      border-radius:7px;
    }
    .button-minicart-cart div{
      font-size : 1vw;
      padding:5px;
      white-space : nowrap;
    }

    .button-minicart-order{
      height:auto;
      border-radius:7px;
      
    }
    .button-minicart-order div{
      font-size : 1vw;
      padding:5px;
    }

    .final-price span{
      font-size : 1vw;
    }
    
    .button-minicart-cart > img {
      width : 1vw;
      height : 1vw;
    }
  }

  @media only screen and (max-width : 786px){
    .cart-item{
      padding-left : 0;
      width:95%;
    }

    .button-minicart-cart{
      width: 65%;
      margin: auto auto 1.5rem;
    }

    .button-minicart-order{
      width: 85%;
      margin: auto auto 1.5rem;
    }

    .quantity-button{
      width : 35px;
    }

    .quantity{
      width : 25px;
    }
  
  }

  @media only screen and (max-width : 380px ){
    .button-text{
      font-size :12px;
    }
  }

`;
