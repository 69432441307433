import * as yup from "yup";
import Box from "../Box";
//import Button from "../buttons/Button";
//import IconButton from "../buttons/IconButton";
import FlexBox from "../FlexBox";
import Icon from "../icon/Icon";
import TextField from "../text-field/TextField";
import { H1, H3, H5, H6, SemiSpan, Small, Span } from "../Typography";
import { StyledSessionCard } from "./SessionStyle";
import { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { Link, router } from '@inertiajs/react';
//import { Inertia } from '@inertiajs/inertia';
import AxiosService from "../../services/axios.service";
import { useAppContext } from "../../contexts/app/AppContext";
import useWindowSize from "../../hooks/useWindowSize";
import { Alert } from "@mui/material";
import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useTranslation } from "react-i18next";

const Logins = (props) => {

  const { state, dispatch, isCategoryPage } = useAppContext();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [erros, setErrors] = useState(false);
  const [status, setStatus] = useState(true)
  const [checked, setChecked] = useState(true)
  const { t } = useTranslation()
  const screenWidth = useWindowSize()


  //console.log('login', state);

  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisibility((visible) => !visible);
  }, [])

  const handleFormSubmit = async (values) => {

    //Inertia.post('login', values)
    AxiosService
      .post('/customer/login', values)
      .then((response) => {

        setStatus(response.data.status)

        if (response.data['status']) {
          props.setOpenSnack(true)

          dispatch(
            {
              'auth': {
                type: "SIGN_IN",
                payload: {
                  token: '',
                  isAuth: true,
                  customer: response.data['customer_name']
                },
              }
            }
          );

          props.setOpen3(false)  

          if (!isCategoryPage) {
            localStorage.removeItem('login')
          }

          setTimeout(() => {
            //router.visit(window.location.pathname);
            window.location.reload();
          }, 2000)

        }
      }).catch(
        function (error) {
          console.log(error)

        }
      )
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik({
    onSubmit: handleFormSubmit,
    initialValues,
    validationSchema: formSchema,
  });

  return (
    <>

      <StyledSessionCard mx="auto" my={screenWidth > 1600 ? "6rem" : '2rem'} boxShadow={'unset'} >

        <form className="content" onSubmit={handleSubmit}>

          <H1 className='login-title' >
            {t("login.login")}
          </H1>
          <H5
            fontSize="19px"
            className='sub-title'
            textAlign="center"
            mb="2.25rem"
          >
            {t("login.nomember")} <Span fontSize='inherit' color="royalblue"><Link href="/customer/register">{t("login.register")}</Link></Span>
          </H5>

          <TextField
            mb="0.75rem"
            name="email"
            placeholder="example@mail.com"
            label="email"
            type="email"
            fullwidth
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email || ""}
            errorText={touched.email && errors.email}
            endAdornment={
              <Icon variant="medium" defaultcolor="white" pr={'6px'}>
                mail
              </Icon>
            }
          />
          <TextField
            mb="1rem"
            name="password"
            placeholder="************"
            autoComplete="on"
            type={passwordVisibility ? "text" : "password"}
            label={t("login.password")}
            fullwidth
            endAdornment={
              <IconButton
                size="small"
                type="button"
                backgroundColor={'unset'}
                p={0}
                color={passwordVisibility ? "gray.700" : "gray.600"}
                onClick={togglePasswordVisibility}
              >
                <Icon variant="medium" defaultcolor="white" >
                  {passwordVisibility ? "eye-alt" : "eye"}
                </Icon>
              </IconButton>
            }
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password || ""}
            errorText={touched.password && errors.password}
          />

          {/* {!status && <H5>Λάθος κωδικός ή όνομα χρήστη</H5>} */}

          {!status && <Alert severity="error">{t("login.errormessage")}</Alert>
          }

          {
            (!props.fromAdmin) ?
              <>
              </>
              :
              null
          }
          <FormGroup sx={{ ml: 1.8 }}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: 'silver',
                    '&.Mui-checked': {
                      color: 'silver',
                    },
                  }}

                  defaultChecked
                  onChange={(e) => setFieldValue('remember_me', e.target.checked)}
                />}
              label={t("login.rememberme")}
            />
          </FormGroup>

          <FlexBox justifyContent="space-between" alignItems={'center'} bg="transparent" p={3} pt={1}>
            <Link href="/customer/forgot-password"><SemiSpan mr={4} fontSize={screenWidth > 1980 ? '0.8vw' : 'inherit'}>{t("login.forgotpassword")}</SemiSpan></Link>
            <Button
              variant="contained"
              type="submit"
              className='login-button'
              marginLeft={1}
            >
              {t("login.login")}
            </Button>

          </FlexBox>
        </form>

      </StyledSessionCard>
    </>
  );
};

const initialValues = {
  email: "",
  password: "",
  remember_me: true
}

const formSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("${path} is required"),
  password: yup.string().required("${path} is required"),
})

export default Logins;
