import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

const useWindowHeight = () => {
  const [height, setHeight] = useState(null);

  const windowListener = useCallback(
    debounce(() => {
      if (window) setHeight(window.innerHeight);
    }, 250),
    []
  );

  useEffect(() => {
    if (window) {
      setHeight(window.innerHeight);
      window.addEventListener("resize", windowListener);
    }
    return () => window.removeEventListener("resize", windowListener);
  }, []);
  
  return height;
};

export default useWindowHeight;