const combineReducers = (reducers) => {
  return (state = {}, action) => {


    const newState = {};
    
    for (let key in reducers) {

      if(action[key]){
        newState[key] = reducers[key](state[key], action[key])
      }else{
        newState[key] = state[key];
      }
     
    }

    return newState;
  };
};

export default combineReducers;
