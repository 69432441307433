import { deviceSize } from "../../utils/constants";
import styled from "styled-components";
import { space } from "styled-system";
import { getTheme } from "../../utils/utils";
import Card from "../Card";
import Box from "../Box";

export const StyledProductCard1 = styled(Box)`

  transition: all 250ms ease-in-out;
  margin: auto;
  overflow: visible;
  height: 100%;
  display: flex;
  flex-direction: column;

}

  .initial-price{
    color : #707070;
    position : relative;
  }

  .initial-price:after {
    content: "";
    border-top: 2px solid;
    border-color: #707070;
    position: absolute;
    right: 0;
    left: 0;
    top: 50%;
    transform: rotate(-10deg);
}

.discount-badge{
  color: white;
  font-size: 16px;
  position: absolute;
  right: 0%;
  background-color: black;
  border: solid 2px white;
  border-radius: 5px;
  padding: 5px 20px 5px 20px;
  top: 2%;
  z-index : 1;
}

  .react-reveal{
    width: 100%;
  }

  .white-bg{
    color:white;
  }

  .grey-bg{
    color:grey;
  }

  .size-availability{
    flex-wrap : wrap;
    bottom :${props => props.isIOS && '1px!important'};
  }

  .title{
    overflow: hidden;
    white-space: break-spaces;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position : relative;
    top:${props => props.isIOS && '1px!important'};
    min-height : 60px;
  }

  iiimg {

    min-width:200px;
    height: 30.7vw;
    width: 22vw;
    max-width : 362px;
    max-height : 590px;
  }

  &:hover {
    .details {
      .add-cart {
        display: flex;
      }
    }
    .image-holder {
      .extra-icons {
        display: block;
      }
    }
  }

  .size{
    font-size : 0.8vw;
  }

  .diat{
    font-size : 0.9vw;
    font-weight : 200;
    position : relative;
    top : 2px;
  }

  .price{
    font-size : 20px;
  }

  .image-holder {
    position: relative;
    display: inline-block;
    text-align: center;

    .extra-icons {
      display: none;
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
      cursor: pointer;
      z-index: 2;
    }

    @media only screen and (max-width: ${deviceSize.sm}px) {
      display: block;

    }



    iiimg:{
      height : 40vw;
      width : 20vw;
    }
  }

  .size{
    width:auto;
    height:auto;
    min-width:14px;
    min-height: 14px;
    padding-left:3px;
    padding-right:3px;
    margin-right : 3px;
  }

  .details {

    .title,
    .categories {
      text-overflow: ellipsis;
      font-weight:400;
    }

    .icon-holder {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }

    .favorite-icon {
      cursor: pointer;
    }
    .outlined-icon {
      svg path {
        fill: ${getTheme("colors.text.hint")};
      }
    }
    .add-cart {
      display: none;
      flex-direction: column;
      align-items: center;
      margin-top: auto;
    }
  }

  @media only screen and (min-width: 432px) and (max-width: 1025px) {
    align-items : center;
    .size{
      padding-left: 1px;
      padding-right: 1px;
      font-size : 8px;
    }

    .diat{
      font-size : 15px;
    }

    .size{
      font-size : 10px;
    }
  }

  @media only screen and (min-width: 1980px) {

      .diat{
        top : 6px;
      }

      .size-availability{
        align-items : flex-end;

        .size{
          font-size : 0.6vw;
          border-radius:6px;
          padding-right: 6px;
          padding-left: 6px;
        }

        div{
          gap:1px;
        }

        span{
          font-size:1vw;
          font-weight:300;
        }
      }

      .title{
        font-size:1.2vw;
        min-height : 4vw;

      }

      .price{
        font-size : 1.2vw;
      }
  }

  @media only screen and (max-width: ${deviceSize.lg}px) {
    .title{
     min-height : 55px;
    }
 }

 @media only screen and (max-width: ${deviceSize.md}px) {
  .title{
   min-height : 45px;
  }
}

  @media only screen and (min-width: 1125px) {
    .size{
      min-width : 20px;
      margin-right: 5px;
    }

    .title{
      position : relative;
      top:1px;
    }

    .size-availability{
      bottom : 1px;
    }



  }

  @media only screen and (max-width: 768px) {
    align-items : center;

    .details {
      .add-cart {
        display: flex;
    }

    .size-availability{
      width:100%;
      gap : 5px;
    }

    }

    .diat{
      font-size : 15px;

    }
    .size{
      font-size :15px;
      min-width:1.5rem;
      padding-left: 3px;
      padding-right: 3px;

    }
  }

  @media only screen and (max-width: ${deviceSize.s}px) {
    .discount-badge{
      padding : 2px 5px 2px 5px;
      font-size : 10px;
  }

  @media only screen and (min-width : 520px) and (max-width: 1125px) {
    .title{
      font-size:20px;
    }

  }

  @media only screen and (max-width: 520px) {

    .sizes-wrapper{
      width:auto;
      // justify-content: flex-end;
      gap: 2px;
      /flex-wrap:wrap;
    }

    .title{
      font-size : 12px;
      min-height : 37px;
    }

    .diat{
      font-size : 12px;

    }
    .size{
      font-size : 8px;
      width : auto;
      padding-left: 2px;
      padding-right: 2px;
      min-width:18px;
      margin-right : 2px;
      line-height : 2;
    }

    .price{
      font-size : 16px;
    }

  }

`;

export const StyledProductCard2 = styled.div`
  text-align: center;

  img {
    border-radius: 10px;
    width: 100%;
  }
  .title {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
    color: ${getTheme("colors.text.secondary")};
  }
  .price {
    margin: 0;
    color: ${getTheme("colors.primary.main")};
  }
`;

export const StyledProductCard3 = styled.div`
  display: inline-block;
  border-radius: 8px;
  background-color: ${getTheme("colors.body.default")};
  transition: all 250ms ease-in-out;

  &:hover {
    box-shadow: ${getTheme("shadows.6")};
    .details {
      .add-cart {
        display: flex;
      }
    }
  }

  .image-holder {
    position: relative;
    display: inlin-block;
    text-align: center;

    .sale-chip {
      display: inline-block;
      position: absolute;
      top: 0.625rem;
      left: 0.625rem;
      padding: 0.4rem 0.78rem;
      border-radius: 500px;
      color: white;
      font-size: 13px;
      background: ${getTheme("colors.primary.main")};
    }
  }

  .details {
    padding: 1rem;

    h4 {
      margin: 0 0 0.5rem;
      color: ${getTheme("colors.text.secondary")};
    }

    .price {
      display: flex;
      margin-top: 0.5rem;
      font-weight: 600;

      h4 {
        margin: 0px;
        padding-right: 0.5rem;
        color: ${getTheme("colors.primary.main")};
      }
      del {
        color: ${getTheme("colors.text.hint")};
      }
    }

    .icon-holder {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }

    .favorite-icon {
      cursor: pointer;
    }
    .outlined-icon {
      svg path {
        fill: ${getTheme("colors.text.hint")};
      }
    }
    .add-cart {
      display: none;
      align-items: center;
      margin-top: auto;

      span {
        padding: 0px 0.5rem;
        font-size: 15px;
        font-weight: 600;
      }
    }
  }
`;

export const StyledProductCard5 = styled.div`
  display: inlin-block;
  text-align: center;

  img {
    border-radius: 10px;
    display: block;
    width: 100%;
  }
  .title {
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 700;
    color: ${getTheme("colors.text.primary")};
  }
`;

export const StyledProductCard7 = styled.div`
  position: relative;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${getTheme("colors.body.paper")};
  box-shadow: ${getTheme("shadows.4")};

  .product-details {
    padding: 20px;
  }
  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media only screen and (max-width: 435px) {
    flex-wrap: wrap;
    img {
      height: auto;
      min-width: 100%;
    }
    .product-details {
      // padding: 1rem;
    }
  }
  ${space}
`;

export const StyledProductCard9 = styled(Card)`
  box-shadow : unset;
  border-radius : 0;

  .initial-price{
    color : #707070;
    position : relative;
  }

  .initial-price:after {
    content: "";
    border-top: 2px solid;
    border-color: #707070;
    position: absolute;
    right: 0;
    left: 0;
    top: 50%;
    transform: rotate(-10deg);
}

  .size{
    min-width : 20px;
    padding : 0px 3px 0px 3px;
  }

  .quick-view {
    display: none;
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    cursor: pointer;
  }
  .categories {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .categories {
    display: flex;
    .link {
      margin-right: 0.5rem;
      text-decoration: underline;
      font-size: 14px;
      color: ${getTheme("colors.text.hint")};
    }
  }

  h4 {
    margin: 0.5rem 0px;
    color: ${getTheme("colors.text.secondary")};
    text-align: left;
  }

  .grey-bg{
    color:grey;
  }

  .price {
    display: flex;
    margin-top: 0.5rem;
    font-weight: 600;
    font-size : 20px;
    h4 {
      margin: 0px;
      padding-right: 0.5rem;
      color: ${getTheme("colors.primary.main")};
    }
    del {
      color: ${getTheme("colors.text.hint")};
    }
  }

  .icon-holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .favorite-icon {
    cursor: pointer;
  }
  .outlined-icon {
    svg path {
      fill: ${getTheme("colors.text.hint")};
    }
  }
  .add-cart {
    display: none;
  }

  &:hover {
    .add-cart {
      display: flex;
    }
    .quick-view {
      display: block;
    }
  }

  .img-list-view{
    width:173px;
  }

  .width-auto{
    width:auto;
  }

  @media only screen and (min-width: 1980px) {
    .img-list-view{
      width:10vw;
    }

    .title-list-view{
      font-size : 1.5vw;
    }

    .diat{
      font-size : 1vw;
    }

    .description-list-view{
      font-size : 0.6vw;
    }

    .size{
      font-size:0.8vw;
      width:100%;
      border-radius: 6px;
      min-width:1.5vw;
      margin-left : 0.5rem;
    }
  }

  @media only screen and (max-width: 768px) {
    .img-list-view{
      width : unset;
      margin : auto;
    }

    .diat{
      font-size : 14px;
    }

  }
`;
