import systemCss from "@styled-system/css";
import { colorOptions } from "../../interfaces";
import styled from "styled-components";
import {
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  layout,
  LayoutProps,
  shadow,
  space,
  SpaceProps,
  variant,
} from "styled-system"

import { themeGet } from "@styled-system/theme-get";
export const getTheme = (query: any, fallback: any) =>
  themeGet(query, fallback);

const Button = styled.button<
  ColorProps &
  BackgroundProps &
  BorderProps &
  SpaceProps &
  ButtonProps &
  LayoutProps
>(
  ({ color, fullwidth }) =>
    systemCss({
      display: "flex",
      width: fullwidth ? "100%" : "unset",
      justifyContent: "center",
      //maxWidth : 500,
      alignItems: "center",
      outline: "none",
      border: "solid 1px white",
      cursor: "pointer",
      padding: "11px 1.5rem",
      fontSize: "1rem",
      fontWeight: 600,
      color: 'white',
      background: "transparent",
      transition: "all 150ms ease-in-out",
      lineHeight: 1,
      "&:focus": {
        boxShadow: 3, //shadows[3]
      },
      "&:hover": {
        transform: 'scale(1.02)',
        backgroundColor : 'black' ,
       
      },
      "&:disabled": {
        bg: "text.disabled",
        color: "text.hint",
        borderColor: "text.disabled",
        cursor: "unset",
        "svg path": {
          fill: "text.hint",
        },
        "svg polyline, svg polygon": {
          color: "text.hint",
        },
        "@media only screen and (min-width : 1980px)" : {
          padding : '1vw',
          borderRadius : '7px',
        },
        
      },
      "@media only screen and (min-width : 1980px)" : {
        padding : '0.2vw 1vw',
        borderRadius : '7px',
      }
    }),
  ({ theme, color }) =>
    variant({
      prop: "variant",
      variants: {
        text: {
          border: "none",
          color: `${color}.main`,
          "&:hover": {
            bg: 'black',
          },
        },
        outlined: {
          padding: "10px 16px",
          color: `${color}.main`,
          border: "1px solid",
          borderColor: color ? `${color}.main` : "text.disabled",

          "&:enabled svg path": {
            fill: color
              ? `${theme.colors[color]?.main} !important`
              : "text.primary",
          },
          "&:enabled svg polyline, svg polygon": {
            color: color
              ? `${theme.colors[color]?.main} !important`
              : "text.primary",
          },
          "&:focus": {
            boxShadow: `0px 1px 4px 0px ${theme.colors[color]?.light}`,
          },
          "&:hover:enabled": {
            bg: color && `${color}.main`,
            borderColor: color && `${color}.main`,
            color: color && `${color}.text`,
            "svg path": {
              fill: color
                ? `${theme.colors[color]?.text} !important`
                : "text.primary",
            },
            "svg polyline, svg polygon": {
              color: color
                ? `${theme.colors[color]?.text} !important`
                : "text.primary",
            },
          },
        },
        contained: {
          border: "none",
          color: `${color}.text`,
          bg: `${color}.main`,
          "&:focus": {
            boxShadow: `0px 1px 4px 0px ${theme.colors[color]?.light}`,
          },
          "&:enabled svg path": {
            fill: color
              ? `${theme.colors[color]?.text} !important`
              : "text.primary",
          },
          "&:enabled svg polyline, svg polygon": {
            color: color
              ? `${theme.colors[color]?.text} !important`
              : "text.primary",
          },
        },
      },
    }),
  variant({
    prop: "size",
    variants: {
      large: {
        height: "56px",
        px: 30,
      },
      medium: {
        height: "48px",
        px: 30,
        
      },
      small: {
        height: "40px",
        fontSize: 14,
        // '@media only screen and (min-width : 1980px)' : {
        //   fontSize : '0.7vw'
        // }
      },
      xsmall : {
        height : '29px',
        fontSize: 14,
        borderColor : '#E5E5E5',
        fontWeight : 200,
        p : 2
      }
    },
  }),
  compose(color, layout, space, border, shadow)
);

Button.defaultProps = {
  size: "small",
  borderRadius: 5,
};

export default Button;
