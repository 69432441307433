import styled from "styled-components";
import { Fragment } from "react";
import Box from "../Box";

const StyledSwiper = styled(Box)`
position : relative;
 img{
    cursor : pointer;
 }

 .custom-next, .custom-prev {
  position: absolute;
  z-index : 1;
 }

 .custom-prev {
  left: 36%;
 }

 .custom-next{
  bottom : 0 ;
  left: 36%;
 }

 .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
   ccontent : ' '!important;
 }


 .swiper-pagination-bullet {
        
   background-color:white;
   width : 12px;
   height : 12px;
   border : solid 1px black;
}   

@media only screen and (max-width : 1070px) {
  .custom-next{
    position: absolute;
    top: 43%;
    right: 5%;
  }

  .custom-prev{
    position: absolute;
    top: 43%;
    left: 5%;
  }
}

`
export default StyledSwiper