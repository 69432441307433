import React from 'react'
import styled from "styled-components";
import Box from "../Box";


const StyledConfirmDelete = styled(Box)`

`



export default StyledConfirmDelete