import styled from "styled-components";
import { getTheme } from "../../utils/utils";
import Box from "../Box";
import FlexBox from "../FlexBox";
import NavLink from "../nav-link/NavLink";
import { Link } from '@inertiajs/react';
import { H2 } from "../Typography";


export const DashboardNavigationWrapper = styled(Box)`

  display:flex;
  flex-direction : column;
  gap : 1rem;
  color : white;

  .signout-button{
    height :69px;
    justify-content : flex-start;
    font-size : 25px;
    font-weight : 400;
    padding : 0px 1.2rem;
  }

  .signout-button-text{
    gap:1.7rem;
  }

  .logout-button-text{
    font-size: 25px;
    font-weight: 400;
    color: white;
    white-space : nowrap;
  }

  .dashboard-icon{
    margin : auto;
  }

  @media only screen and (min-width : 1980px){

    padding-top: 2.4vw;
    
    
    .icon-wrapper > div{
      width: 1vw;
      height: 1vw;
    }

   

    .signout-button , .signout-wrapper{
      height : 3vw;
    }

    .signout-button-text{
      gap:3.4rem;
    }

    .dashboard-icon > div{
      width:1vw;
      height:unset;
    }

  }

  @media only screen and (max-width: 1150px) {
    .signout-button-text {
      font-size : 21px;
      font-weight : 400;
    }
  }

  @media only screen and (max-width: 1024px) {
    
    padding-top : 3rem;

    .signout-button{
      background-color : rgba(255, 255, 255, 0.05);;
    }

    .signout-button-text{
      gap:0.8rem;
    }

  }

  @media only screen and (max-width: 768px) {
    height: calc(100vh - 64px);
    box-shadow: none;
    overflow-y: auto;
    
  }

  @media only screen and (max-height : 768px){

    margin-top : 0;
    padding-top : 0;
    //width:80%;

    .signout-button{
      height : 50px;
    }

  }


`;
//export const StyledDashboardNav = styled(Link)<{ isCurrentPath?: boolean }>`
export const StyledDashboardNav = styled(Link) <{ isCurrentPath?: boolean }>`

  display: flex;
  justify-content: space-between;
  align-items: center;
  //border-left: 4px solid;
  padding-left : 1.3rem;
  transition : all 150ms ease-in-out;

  

  h2{
    color: ${({ isCurrentPath }) =>
    isCurrentPath ? 'royalblue' : "inherit"};
  }
  
  border-left-color: ${( isCurrentPath ) => isCurrentPath ? 'white' : "transparent"};

  .dashboard-nav-icon-holder {
    color: ${getTheme("colors.gray.600")};
  }

  height : 69px;
  background-color : #00000087;
  border-radius : 12px;

  .dashboard-item-wrapper{
    gap : 2rem;
  }

  &:hover {
    border-left-color: ${getTheme("colors.primary.main")};
    transform : scale(1.02);
    background-color : black;
    
  }
    .dashboard-nav-icon-holder {
      color: ${getTheme("colors.primary.main")};
    }
  }

  @media only screen and (min-width: 1980px) {
  
    height : 3vw;

    h2 {
      font-size : 1.4vw ;
    }

    .dashboard-item-wrapper{
      gap : 4rem;
    }

}

  @media only screen and (max-width: 1150px) {
      h2 {
        font-size : 21px;
      }
    
  }

  @media only screen and (max-width : 1024px) {

    
    

    background-color : #FFFFFF0D ;
    margin-top : 0rem;
    gap : 0;

    .dashboard-item-wrapper{
      gap : 1rem;
    }
    
  }

  @media only screen and (max-height: 768px) {
    height:50px;
   }
`;

export const StyledH2 = styled(H2)`

@media only screen and (min-width: 1980px) {
  font-size : 1.2vw;
`

export const StyledDashboardPageTitle = styled(FlexBox)``;
