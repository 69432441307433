import * as React from 'react';
import StyledConfirmDelete from './ConfirmDeleteStyle';

// @MUI IMPORTS
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiButtonBase-root': {
        backgroundColor: 'black',
        color: 'white',
        textTransform: 'unset',
        '&:hover': {
            color: 'black',
            backgroundColor: 'white'
        },
        '@media only screen and (min-width : 1980px)': {
            fontSize: '0.7vw'
        }
    },
    '& .MuiTypography-root': {
        '@media only screen and (min-width : 1980px)': {
            fontSize: '0.7vw'
        }
    }
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}


const ConfirmDelete = ({ item, open, setOpen, handleClickOpen, handleClose, handleCartAmountChange }) => {

    const { t } = useTranslation()

    return (
        <StyledConfirmDelete>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogContent sx={{ width: '90%' }} >
                    <Typography gutterBottom>
                        {t("dialog.delete")}
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()}>
                    {t("no")}
                    </Button>
                    <Button onClick={() => handleCartAmountChange(0, item)}>
                    {t("yes")}
                    </Button>
                </DialogActions>
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose()}
                    sx={{
                        position: 'absolute',
                        right: 2,
                        top: 8,
                        color: '#9e9e9e!important',
                        backgroundColor: 'transparent!important'
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </BootstrapDialog>

        </StyledConfirmDelete>
    );
}

export default ConfirmDelete