import * as React from 'react';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// @mui imports
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import StyledColorSelect from './ColorSelectStyle';
import Box from '../Box';
import FlexBox from '../FlexBox';
import { cp } from 'fs';

import Image from '../../Components/Image';


interface Props {
    product: {
        variants?: {
            color_code_id: number
            color_label: string
            color_swatch_value: string
            color_image: string
        }[];
    };
    color: string
    setColor: (color: string | null) => void
    setKey: (key: any) => void
    setButtonClicks: (buttonClicks: number) => void
    setInitialPrice
    setDiscountPrice
    findMinPriceByColorWithDiscount
    findMinPriceByColorWithNoDiscount
}

interface Color {
    color_code_id: number
    color_label: string
    color_swatch_value: string
    color_image: string

}

function createColor({ color_code_id, color_label, color_swatch_value, color_image, color_sort_order }: Color): Color {
    return { color_code_id, color_label, color_swatch_value, color_image, color_sort_order }
}


const StyleOptions = {
    color: 'white',
    borderRadius: 1,
    //  border: 'solid 1px white',
    height: 35,
    marginLeft: 0,
    mt: 1,
    flexBasis: '20%',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
}

const ColorSelect: React.FC<Props> = ({
    product,
    color = '',
    setColor,
    setKey,
    setButtonClicks,
    setInitialPrice,
    setDiscountPrice,
    findMinPriceByColorWithDiscount,
    findMinPriceByColorWithNoDiscount,
}) => {

    const { t } = useTranslation()
    const colorOptions: Color[] = useMemo(
        () =>
            (product.variants || []).map((variant) =>
                createColor({
                    color_code_id: variant.color_code_id,
                    color_label: variant.color_label,
                    color_swatch_value: variant.color_swatch_value,
                    color_image: variant.color_image,
                    color_sort_order: variant.color_sort_order
                })
            ),
        [product.variants]
    )

    const selectedColor = color;

    const uniqueColors: Color[] = useMemo(
        () => [...new Map(colorOptions.sort((a, b) => a.color_sort_order - b.color_sort_order).map((color) => [color.color_code_id, color])).values()],
        [colorOptions]
    )

    useEffect(() => {
        if (uniqueColors.length == 1) {

            setDiscountPrice(findMinPriceByColorWithDiscount[uniqueColors[0].color_code_id])
            setInitialPrice(findMinPriceByColorWithNoDiscount[uniqueColors[0].color_code_id])
            setColor(uniqueColors[0].color_code_id)

        }
    }, [])

    const handleColorChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setButtonClicks(0)
            setColor(event.target.value);
            setKey((prevKey: number) => prevKey + 1);
        }, [setColor])

    const handleOnClick = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {

            if (event.target.value == color) {

                setDiscountPrice(10)
                setInitialPrice(10)
                setColor(null);
            } else {

                setDiscountPrice(findMinPriceByColorWithDiscount[event.target.value])
                setInitialPrice(findMinPriceByColorWithNoDiscount[event.target.value])

                //setColor(event.target.value);
            }

        }, [color])

    return (
        <>
            {uniqueColors &&
                <StyledColorSelect width='100%'>
                    <FormControl sx={{ width: 'inherit' }} >
                        <FormLabel sx={{ color: 'white' }} id="demo-controlled-radio-buttons-group">{t("product.colorselect")}</FormLabel>
                        <RadioGroup
                            aria-labelledby="controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={color}
                            onChange={handleColorChange}
                            sx={{ flexDirection: 'row', mb: 2 }}
                        >
                            {uniqueColors.map((color: Color, ind) => (

                                <Box key={ind}>
                                    <FormControlLabel
                                        sx={[StyleOptions, { border: selectedColor == color.color_code_id ? 'solid 1px royalblue' : 'solid 1px white' }]}
                                        value={color.color_code_id}
                                        control={<Radio />}
                                        onClick={handleOnClick}
                                        className='color-wrapper'
                                        label={
                                            <FlexBox alignItems='center'>
                                                <Box className='color-label'>
                                                    {color.color_label}
                                                </Box>

                                                {
                                                    color.color_image ?

                                                        <Image
                                                            border={selectedColor == color.color_code_id ? 'solid 2px white' : ''}
                                                            className="swatch-value" src={color.color_image}
                                                        />

                                                        :

                                                        <Box
                                                            className='color-bg'
                                                            ml={10}
                                                            width={18}
                                                            height={18}
                                                            backgroundColor={color.color_swatch_value}
                                                            borderRadius={7}
                                                            border={selectedColor == color.color_code_id ? 'solid 2px white' : ''}
                                                        />
                                                }
                                            </FlexBox>}
                                    />
                                    <Box backgroundColor={color.color_swatch_value} ></Box>
                                </Box>

                            ))}
                        </RadioGroup>
                    </FormControl>
                </StyledColorSelect>
            }
        </>
    )
}

export default ColorSelect