import React, { createContext, useContext, useMemo, useReducer, useEffect, useState } from 'react';
import AxiosService from "../../services/axios.service";

// import { ContextDevTool } from "react-context-devtool";
import {
  initialState,
  rootActionType,
  rootReducer,
} from '../../reducers/rootReducer';

const AppContext = createContext(null);

export const AppProvider: React.memo = ({ children }) => {

  const [state, dispatch] = useReducer(rootReducer, initialState);
  const [favicon, setFavicon] = useState(null);
  const [logo, setLogo] = useState(null);
  const [categories, setCategories] = useState([]);
  const [openSnackCart, setOpenSnackCart] = useState(false)
  const [openSnackRemove, setOpenSnackRemove] = useState(false)
  const [openSnackUpdate, setOpenSnackUpdate] = useState(false)
  const [openSnackError, setOpenSnackError] = useState(false)
  const [openSnackProfileUpdate, setOpenSnackProfileUpdate] = useState(false)
  const [openSnackProfileCreate, setOpenSnackProfileCreate] = useState(false)
  const [openSnackCreateAcc, setOpenSnackCreateAcc] = useState(false)
  const [openSnackAFMRegisterNotFound, setOpenSnackAFMRegisterNotFound] = useState(false)
  const [openSnackContact, setOpenSnackContact] = useState(false)
  const [openSnackEmptyCart, setOpenSnackEmptyCart] = useState(false)
  const [showMessageSnack,setShowMessageSnack] = useState(false)
  const [alert, setAlert] = useState(false)
  const [message, setMessage] = useState(false)
  const [footerVisible, setFooterVisible] = useState(true);
  const [isCategoryPage, setIsCategoryPage] = useState(false);
  const [updateQ, setUpdateQ] = useState(false);
  const [isMounted, setIsMounted] = useState(true)
  const [imgsLoaded, setImgsLoaded] = useState(true)

  useEffect(() => {

    const getHeaderData = async () => {
      
      const headerData = await AxiosService.get('/api/header-data');
      setFavicon(headerData.data.favicon)
      setLogo(headerData.data.logo)

      const categories = await AxiosService.get('/categories');
      setCategories(categories.data.categories)

    };

    //getHeaderData(); // run it, run it

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);



  /*
function initCart() {
    AxiosService.get('/mini-cart')
        .then((response) => {

            if (!response.data['status']) {
                return;
            }

            for (let i = 0; i < response.data.mini_cart.cart_items.length; i++) {
                cartInitialState.cartList.push(response.data.mini_cart.cart_items[i])
            }


        }).catch(
            function (error) {
                console.log(error)
            }
        )
}
  */


  const fixPrice = (price) => {
    if (price.toFixed(2) - price > 0) {

      //return price.toFixed(3);
    }

    return price.toFixed(2);

  }


  /*useEffect(() => {

      dispatch(
        {
          'layout': {
            type: "FAVICON",
            payload:favicon,
          }
        }
      );

  }, [favicon]);*/



  const contextValue = useMemo(() => {


    return {
      state, dispatch, logo, favicon, categories, isMounted, setIsMounted, imgsLoaded, setImgsLoaded, openSnackEmptyCart, setOpenSnackEmptyCart,
      openSnackCart, setOpenSnackUpdate, openSnackRemove, setOpenSnackRemove, openSnackUpdate, setOpenSnackCart,
      openSnackError, setOpenSnackError, openSnackProfileUpdate, setOpenSnackProfileUpdate, openSnackProfileCreate, setOpenSnackProfileCreate, openSnackCreateAcc, setOpenSnackCreateAcc,
      openSnackAFMRegisterNotFound, setOpenSnackAFMRegisterNotFound, footerVisible, setFooterVisible, openSnackContact, setOpenSnackContact, fixPrice, isCategoryPage, setIsCategoryPage, setCategories, setUpdateQ, updateQ,
      showMessageSnack,setShowMessageSnack,alert, setAlert,message, setMessage
    };
  }, [state, dispatch, logo, categories, favicon, isMounted, setIsMounted, openSnackRemove, openSnackUpdate, openSnackCart, openSnackError, imgsLoaded, setImgsLoaded,
    openSnackProfileUpdate, setOpenSnackProfileUpdate, openSnackProfileCreate, setOpenSnackProfileCreate, openSnackCreateAcc, setOpenSnackCreateAcc,
    openSnackAFMRegisterNotFound, setOpenSnackAFMRegisterNotFound, footerVisible, openSnackContact, setOpenSnackContact, categories, updateQ, openSnackEmptyCart, setOpenSnackEmptyCart,showMessageSnack,alert,message]);



  return (
    <AppContext.Provider value={contextValue}>

      {/* <ContextDevTool context={AppContext} id="app-context" displayName="App" /> */}
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () =>
  useContext<{
    state: typeof initialState;
    dispatch: (args: rootActionType) => void;
    logo: any
    categories: any
    openSnackCart: boolean
    openSnackRemove: boolean
    openSnackUpdate: boolean
    openSnackError: boolean
    setOpenSnackUpdate: any
    setOpenSnackRemove: any
    setOpenSnackCart: any
    setOpenSnackError:React.Dispatch<React.SetStateAction<boolean>>
    openSnackProfileCreate, setOpenSnackProfileCreate: React.Dispatch<React.SetStateAction<boolean>>
    openSnackProfileUpdate, setOpenSnackProfileUpdate: React.Dispatch<React.SetStateAction<boolean>>
    openSnackCreateAcc, setOpenSnackCreateAcc: React.Dispatch<React.SetStateAction<boolean>>
    openSnackAFMRegisterNotFound, setOpenSnackAFMRegisterNotFound: React.Dispatch<React.SetStateAction<boolean>>,
    footerVisible, setFooterVisible: React.Dispatch<React.SetStateAction<boolean>>
    openSnackContact, setOpenSnackContact: React.Dispatch<React.SetStateAction<boolean>>
    fixPrice: any
    isCategoryPage: any
    setIsCategoryPage: React.Dispatch<React.SetStateAction<boolean>>
    setCategories: React.Dispatch<React.SetStateAction<boolean>>
    updateQ: any,
    setUpdateQ: React.Dispatch<React.SetStateAction<boolean>>
    isMounted: boolean
    setIsMounted: React.Dispatch<React.SetStateAction<boolean>>
    imgsLoaded: boolean
    setImgsLoaded: React.Dispatch<React.SetStateAction<boolean>>
    openSnackEmptyCart: boolean
    setOpenSnackEmptyCart: React.Dispatch<React.SetStateAction<boolean>>
  }>(AppContext);

export default { AppContext };
