const CHANGE_CART_AMOUNT = "CHANGE_CART_AMOUNT";
const CHANGE_CART_AMOUNT_AFTER_ORDER = "CHANGE_CART_AMOUNT_AFTER_ORDER";
const SET_CART = "SET_CART";
const GET_CART = "GET_CART";

import { update } from "lodash";
import AxiosService from "../services/axios.service";

var created_at = {}

export const cartInitialState = {
  cartList: [],
};

export type CartItem = {
  id: string | number;
  name: string;
  qty: number;
  price: number;
  imgUrl?: string;
  parent_id: string | number;
  sku: string | number;
  base_total_with_tax: number,
  base_price: number,
  total: number,
  color_attr_id: string | number,
  color_code_id: string | number,
  size_attr_id: string | number,
  size_code_id: string | number,
  product_id: string | number,
  url_key: string,
  tax_percent:number,
  qty_step : string
};

const sizeOrder = {
  S: 1,
  M: 2,
  L: 3,
  XL: 4,
  '2XL': 5,
  '3XL': 6,
  '4XL': 7,
};

export type cartStateType = {
  cartList: CartItem[];
};

export type cartActionType = {
  cart: {
    type: typeof CHANGE_CART_AMOUNT;
    payload: CartItem;
  }
};



export const cartReducer: React.Reducer<cartStateType, cartActionType> = (
  state: cartStateType,
  action: cartActionType
) => {


  switch (action.type) {
    case CHANGE_CART_AMOUNT:
      let cartList = state.cartList;
      let cartItem = action.payload;

      
      //return
      //let exist = cartList.find((item) => item.product_id === cartItem.id);

      //if(!exist){
        let exist = cartList.find((item) => item.product_id === cartItem.product_id);
      //}

      if (cartItem.qty < 1) {
        //console.log('cart_remove');

        return {
          cartList: cartList.filter((item) => item.id !== cartItem.id),
        };
      } else if (exist) {
        return {
          cartList: cartList.map((item) => {
            // console.log('cart_update ID: ' + item.id);
            if (item.product_id === cartItem.product_id) return { ...item, qty: cartItem.qty };
            else return item;
          }),
        };
      } else {

        let cl = [cartItem,...cartList ];

        return {
          cartList: cl.sort((a, b ) => {

            let created_atA = a?.created_at;
          let created_atB = b?.created_at;

          if(!created_at[a?.additional?.product_id]){
            created_at[a?.additional?.product_id] = {};
          }

          if(!created_at[b?.additional?.product_id]){
            created_at[b?.additional?.product_id] = {};
          }
  

     

          if(!created_at[a?.additional?.product_id][a?.additional.attributes.color?.option_id]){
            created_at[a?.additional?.product_id][a?.additional.attributes.color?.option_id] =  a?.created_at

          }

          created_atA = created_at[a?.additional?.product_id][a?.additional.attributes.color?.option_id];

          
          if(!created_at[b?.additional?.product_id][b?.additional.attributes.color?.option_id]){
            created_at[b?.additional?.product_id][b?.additional.attributes.color?.option_id] =  b?.created_at

          }

          created_atB = created_at[b?.additional?.product_id][b?.additional.attributes.color?.option_id];

          const timestampA = new Date(created_atA).getTime()
          const timestampB = new Date(created_atB).getTime()

          //const timestampA = new Date(a?.created_at).getTime()
          //const timestampB = new Date(b?.created_at).getTime()
            const sizeA = a?.additional.attributes?.size?.option_label
            const sizeB = b?.additional.attributes?.size?.option_label
        
            const sizeOrderA = sizeOrder[sizeA] || sizeA?.split('-')[0] || 0
            const sizeOrderB = sizeOrder[sizeB] || sizeB?.split('-')[0] || 0
        
        
            return ( (a?.additional?.product_id - b?.additional?.product_id && timestampB - timestampA) || a?.additional.attributes.color?.option_id - b?.additional.attributes.color?.option_id  || sizeOrderA - sizeOrderB  )
        
        
          
          }),
         
        };
      }

    case CHANGE_CART_AMOUNT_AFTER_ORDER:

      return {
        cartList: []
      }

    case SET_CART:

      created_at = {};
      let parentColor = [];


      /*return {
        cartList: action.payload
      }*/

      return {
        cartList: action.payload.sort((a, b ) => {

          let created_atA = a?.created_at;
          let created_atB = b?.created_at;

          if(!created_at[a?.additional?.product_id]){
            created_at[a?.additional?.product_id] = {};
          }

          if(!created_at[b?.additional?.product_id]){
            created_at[b?.additional?.product_id] = {};
          }
  

     

          if(!created_at[a?.additional?.product_id][a?.additional.attributes.color?.option_id]){
            created_at[a?.additional?.product_id][a?.additional.attributes.color?.option_id] =  a?.created_at

          }

          created_atA = created_at[a?.additional?.product_id][a?.additional.attributes.color?.option_id];

          
          if(!created_at[b?.additional?.product_id][b?.additional.attributes.color?.option_id]){
            created_at[b?.additional?.product_id][b?.additional.attributes.color?.option_id] =  b?.created_at

          }

          created_atB = created_at[b?.additional?.product_id][b?.additional.attributes.color?.option_id];

          const timestampA = new Date(created_atA).getTime()
          const timestampB = new Date(created_atB).getTime()

          //const timestampA = new Date(a?.created_at).getTime()
          //const timestampB = new Date(b?.created_at).getTime()
      
          const sizeA = a?.additional.attributes?.size?.option_label
          const sizeB = b?.additional.attributes?.size?.option_label
          
          const sizeOrderA = sizeOrder[sizeA] || sizeA?.split('-')[0] || 0
          const sizeOrderB = sizeOrder[sizeB] || sizeB?.split('-')[0] || 0
      
      
          return ( (a?.additional?.product_id - b?.additional?.product_id && timestampB - timestampA) || a?.additional.attributes.color?.option_id - b?.additional.attributes.color?.option_id  || sizeOrderA - sizeOrderB  )
      
      
        
        }),
      }

     

    default: {
    }
  }
};
