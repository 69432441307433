import './bootstrap';
import '../css/app.css';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { hydrateRoot } from 'react-dom/client'
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { theme } from "./utils/theme";
import { ThemeProvider } from "styled-components";
import { AppProvider } from "./contexts/app/AppContext";
import { cartInitialState } from "./reducers/cartReducer";
import { authInitialState } from "./reducers/authReducer";
import AxiosService from "./services/axios.service";
import { StrictMode } from "react";
import Sticky from './Components/sticky/Sticky';
import Header from './Components/header/Header';
import './i18n';

function initCart() {
    AxiosService.get('/mini-cart')
        .then((response) => {

            if (!response.data['status']) {
                return;
            }

            for (let i = 0; i < response.data.mini_cart.cart_items.length; i++) {
                cartInitialState.cartList.push(response.data.mini_cart.cart_items[i])
            }


        }).catch(
            function (error) {
                console.log(error)
            }
        )
}

function checkAuth() {
    AxiosService.get('/api/is-auth')
        .then((response) => {

            //console.log('response => ', response)
            if (!response.data['status']) {
                return;
            }

            authInitialState.isAuth = response.data['isAuth'];
            authInitialState.customer = response.data['customer'];


        }).catch(
            function (error) {
                console.log(error)
            }
        )
}

createInertiaApp({

    title: (title) => `${title}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({ el, App, props }) {
        //checkAuth();
        //initCart();

        const root = createRoot(el);
        root.render(
            <Suspense fallback="loading" >
                <ThemeProvider theme={theme}>
                    <AppProvider>
                        <App {...props} />
                    </AppProvider>
                </ThemeProvider>
            </Suspense>
        );

        /*hydrateRoot(el,
            <Suspense fallback="loading" >
                <ThemeProvider theme={theme}>
                    <AppProvider>
                        <App {...props} />
                    </AppProvider>
                </ThemeProvider>
            </Suspense>
            )*/

    },

    progress: {
        color: '#4B5563',
    },


});
