import * as React from 'react';
import { useState, useEffect, useRef, } from 'react';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';

import IconButton from '../buttons/IconButton';
import Icon from '../icon/Icon';
import { Link, router } from '@inertiajs/react';
import AxiosService from '../../services/axios.service';
import StyledSearch, { StyledGridSearch, StyledEndorment } from './SearchStyle';
import { useTranslation } from 'react-i18next';
import CategoryPageProductsGrid from '../products/CategoryPageProductsGrid';
import StyledCategoryGrid from '../products/CategoryGridStyle';
import ProductCard1 from '../product-cards/ProductCard1';
import Grid from '../grid/Grid';
import Button from '../buttons/Button';
import useWindowSize from '../../hooks/useWindowSize';
import FlexBox from '../FlexBox';
import { useAppContext } from '../../contexts/app/AppContext';
import FocusLock from 'react-focus-lock';


const theme = createTheme({
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: 'white!important',
                    boxShadow: 'unset!important',
                    "@media only screen and (min-width : 1980px)": {
                        fontSize: '0.8vw!important'
                    }
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: 'white!important',
                    backgroundColor: 'black!important',
                    boxShadow: 'unset!important',

                },
                input: {
                    boxShadow: 'none!important',
                    "@media only screen and (min-width : 1980px)": {
                        fontSize: '1vw!important'
                    }
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    "&:after": {
                        borderColor: 'white'
                    }
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)'
                },
            },
        }
    },
})



const Search = () => {

    const [open, setOpen] = React.useState(false);
    const [searchValue, setSearchValue] = useState(null)
    //const [timeoutId, setTimeoutId] = useState(null)
    const [products, setProducts] = useState(null)
    const [link, setLink] = useState("")
    const inputRef = useRef(null)
    const screenWidth = useWindowSize()
    const handleOpen = () => setOpen(true)
    const handleClose = () => {
        setProducts(null)
        setOpen(false)
    }
    const { fixPrice } = useAppContext()
    const { t } = useTranslation()
    //let timeoutId = null

    const getItems = async (value: any) => {

        if (searchValue) {
            let response = await AxiosService.get(`/search?category=&term=${value}`)
            setProducts(response.data.products)
        } else {
            setProducts([]);
        }
        
    }


    useEffect(() => {

        if (searchValue && searchValue.length < 3) {
            return;
        }

        const timeOutId = setTimeout(() => getItems(searchValue), 200);
        return () => clearTimeout(timeOutId);
    }, [searchValue])

    useEffect(() => {
        setProducts(searchValue?.length <= 0 ? null : products)
        setLink(`/search?category=&term=${searchValue}`)
    }, [products])

    // useEffect(() => {

    //     setTimeout(() => {
    //         if (open && inputRef.current) {
    //             //console.log(inputRef)
    //             inputRef.current.focus()

    //             setTimeout(() => {
    //                 inputRef.current.click()
    //             }, 100)
    //         }


    //     }, 200)

    // }, [open])

    const style = {
        position: 'absolute' as 'absolute',
        top: products === null || products?.length === 0 ? '140px' : products?.length <= 4 ? '345px' : '500px',
        right: '-8%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        //border: '2px solid #000',
        //boxShadow: 24,
        p: 1,
        backgroundColor: 'transparent',
        borderRadius: '15px',
        maxWidth: screenWidth < 1980 ? '700px' : '35vw',
        '@media only screen and (max-width : 1050px)': {
            left: '50%',
        },
        '@media only screen and (max-width : 900px)': {
            top: products === null || products?.length === 0 ? '110px' : products?.length <= 4 ? '324px' : '324px',

        },
        '@media only screen and (min-width : 1980px)': {
            top: products === null || products?.length === 0 ? '6.5vw' : products?.length <= 4 ? '16vw' : '23vw',

        }
    }

    const handleChange = (e) => {
        setSearchValue(e.target.value);
        //getItems(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        handleClose()
        window.location.href = `/search?category=&term=${searchValue}`
    }

    return (
        <ThemeProvider theme={theme}>

            <StyledSearch>

                <FlexBox>
                    <IconButton onClick={handleOpen} bg="transparent" p={0} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.957" height="23.957" viewBox="0 0 23.957 23.957">
                            <g id="Group_10481" data-name="Group 10481" transform="translate(-1296 -310)">
                                <path id="Path_31837" data-name="Path 31837" d="M659.478,642c4.4,0,7.139.744,8.87,2.413,1.8,1.739,2.609,4.535,2.609,9.065s-.8,7.326-2.609,9.066c-1.732,1.669-4.468,2.413-8.87,2.413s-7.138-.744-8.869-2.413c-1.8-1.74-2.609-4.536-2.609-9.066s.8-7.326,2.609-9.065C652.339,642.744,655.074,642,659.478,642Zm0,20.957c3.844,0,6.152-.572,7.482-1.853,1.362-1.314,2-3.737,2-7.626s-.635-6.312-2-7.625c-1.329-1.282-3.637-1.853-7.482-1.853s-6.153.571-7.481,1.853c-1.363,1.313-2,3.736-2,7.625s.635,6.312,2,7.626C653.326,662.385,655.634,662.957,659.478,662.957Z" transform="translate(648 -332)" fill="#fff" />
                                <path id="Path_31838" data-name="Path 31838" d="M670.957,665.957a1,1,0,0,1-.707-.293l-2.609-2.609a1,1,0,0,1,1.414-1.414l2.609,2.609a1,1,0,0,1-.707,1.707Z" transform="translate(648 -332)" fill="#fff" />
                            </g>
                        </svg>

                    </IconButton>
                </FlexBox>

                <Box className='modal-wrapper'>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box
                            sx={style}
                            noValidate
                            autoComplete="off"
                            component="form"
                        >
                            <Box
                                noValidate
                                autoComplete="off"
                                sx={{ backgroundColor: 'transparent', p: 0, outlineStyle: 'unset' }}
                            >
                                <FocusLock>
                                    <TextField id="standard-basic" label={t("search")} variant='filled'
                                        inputRef={inputRef}
                                        InputProps={{
                                            endAdornment:
                                                <StyledEndorment position='relative'>
                                                    <IconButton marginTop={1} onClick={(e) => handleSubmit(e)} bg="transparent" p={0} mr={4}>
                                                        {/* <Icon className='menuIcon' size="30px">search</Icon> */}
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="23.957" height="23.957" viewBox="0 0 23.957 23.957">
                                                            <g id="Group_10481" data-name="Group 10481" transform="translate(-1296 -310)">
                                                                <path id="Path_31837" data-name="Path 31837" d="M659.478,642c4.4,0,7.139.744,8.87,2.413,1.8,1.739,2.609,4.535,2.609,9.065s-.8,7.326-2.609,9.066c-1.732,1.669-4.468,2.413-8.87,2.413s-7.138-.744-8.869-2.413c-1.8-1.74-2.609-4.536-2.609-9.066s.8-7.326,2.609-9.065C652.339,642.744,655.074,642,659.478,642Zm0,20.957c3.844,0,6.152-.572,7.482-1.853,1.362-1.314,2-3.737,2-7.626s-.635-6.312-2-7.625c-1.329-1.282-3.637-1.853-7.482-1.853s-6.153.571-7.481,1.853c-1.363,1.313-2,3.736-2,7.625s.635,6.312,2,7.626C653.326,662.385,655.634,662.957,659.478,662.957Z" transform="translate(648 -332)" fill="#fff" />
                                                                <path id="Path_31838" data-name="Path 31838" d="M670.957,665.957a1,1,0,0,1-.707-.293l-2.609-2.609a1,1,0,0,1,1.414-1.414l2.609,2.609a1,1,0,0,1-.707,1.707Z" transform="translate(648 -332)" fill="#fff" />
                                                            </g>
                                                        </svg>
                                                    </IconButton>
                                                    <IconButton className='close-icon' bg="transparent" onClick={handleClose} p={0}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </StyledEndorment>

                                        }}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FocusLock>
                            </Box>

                            {products?.length > 0 &&
                                <>

                                    <StyledGridSearch container spacing={1}>
                                        {products?.slice(0, 8).map((product: Product, ind: number) => (
                                            
                                            <Grid className='product-item' id={product.id} item sm={6} md={3} xs={6} key={ind}>

                                                <ProductCard1
                                                    //onClick={((e: any) => handleClick(product.id, product.url_key))}
                                                    searchBox={true}
                                                    id={product.id}
                                                    urlKey={product.url_key}
                                                    imgUrl={product.base_image['category_grid_image_url']}
                                                    title={product.name}
                                                    rating={4}
                                                    price={product.show_total_price ? fixPrice(parseFloat(product.price)) : fixPrice(parseFloat(product?.product_price_discount))}
                                                    key={product.id}
                                                    variants={product.variants ?? []}
                                                    attributes={product.attributes}
                                                    sku={product.sku}
                                                    off={product.off}
                                                    initialPrice={product.is_package || !product.show_total_price ? fixPrice(parseFloat(product.product_price)) : fixPrice(parseFloat(product.initialPrice))}

                                                />

                                            </Grid>
                                        ))}

                                        <Box width='100%' >
                                            <Link href={link}>
                                                <Button
                                                    className='bt-view'
                                                    mt={3}
                                                    mr={2}
                                                >
                                                    {t("buttons.all")} <ChevronRightIcon />
                                                </Button>
                                            </Link>
                                        </Box>
                                    </StyledGridSearch>

                                </>
                            }

                        </Box>

                    </Modal>

                </Box>


            </StyledSearch>

        </ThemeProvider>
    )
}

export default Search