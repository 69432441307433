import styled from "styled-components";
import FlexBox from "../FlexBox";
import Box from "../Box";

const StyledColorSelect = styled(FlexBox)`

    margin-top : 1rem;
    

    .MuiFormControlLabel-label{
        padding-left : 10px;
    }

    path {
       color: transparent !important;
       fill : trasparent !important;
    }

    svg{
        color: transparent !important;
       fill : trasparent !important;
    }

    .color-bg{
        position : absolute;
        right : 14%;
    }

    .Mui-focused { 
        color : white!important;
    }

    label , span{
        font-family : unset;
    }

    .color-wrapper{
        transition : 0.5s ease;
    }

    .color-wrapper:hover{
        transform : scale(1.05);
    }

    img.swatch-value{
        width: 18px;
        height: 18px;
        border-radius: 7px;
        margin-right: 12px;
        position : absolute;
        right : 0;
    }

    @media only screen and (max-width : 420px){
        .color-label{
            font-size : 12px;
            
        }

       .color-bg{
        margin-left : 4px;
       }
    }

    @media only screen and (min-width : 1980px){
        .MuiFormControlLabel-root{
            height : 1.5vw;
        }

        label , .color-label{
            font-size : 0.8vw;
        }

        .color-bg{
            width:0.8vw;
            height:0.8vw;
        }
    }
`

export default StyledColorSelect