import styled from "styled-components";
import Box from "../Box";
import Grid from "../grid/Grid";

const StyledSearch = styled(Box)`

* {
    outline-style : unset;
}

.menuIcon {
    margin-top : 7px;
}

@media only screen and (max-width : 500px){
    svg{
        width : 20px!important;
    }
}

`

export const StyledGridSearch = styled(Grid)`

    margin-top : 1rem;
    backdrop-filter : blur(5px);
    padding:15px;
    border-radius : 25px;
    background-color : rgba(0, 0, 0, 0.35);
    z-index:999999999;
    
    .title , .price{
        font-size : 10px!important;
        min-height: 30px!important;
    }

    .diat{
        font-size : 9px!important;
    }

    .size { 
        font-size : 7px!important;
        min-width:12px!important;
        margin-right : 1px!important;
    }

    .bt-view{
        float : right;
    }

    .items-center{
        min-height : 30px;
    }

    .product-item{
        margin-bottom : 1rem;
    }

 

    @media only screen and (max-width : 768px){
        height : 58vh;
        overflow : auto;

        .size{
            font-size : 8px!important;
        }
    }

    @media only screen and (min-width : 1980px) {
        .title , .diat{
            font-size : 0.4vw!important;
        }

        .price {
            font-size : 14px!important;
        }
        
        .items-center{
            min-height : 1.2vw;
        }

        .diat{
            top : 2px!important;
        }

        .size{
            font-size : 0.4vw!important;
            min-width : 0.9vw;
            padding-left : 5px!important;
            padding-right : 5px!important;
            border-radius  : 3px!important;
        }
    }

  

`

export const StyledEndorment = styled(Box)`

.close-icon{
    position : absolute;
    left:70%;
    top : 5px;
}

@media only screen and (min-width : 1980px){
    .close-icon{
        top : 4px
    }
}




`

export default StyledSearch