import styled from "styled-components";
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from "styled-system";


const Divider2 = styled.div<SpaceProps & LayoutProps & ColorProps>`
  height: 1px;
  background-color: #a4a8af;
  width:100%;
  margin : auto;
  ${color}
  ${space}
  ${layout}
`;

export default Divider2;