import React, { useCallback, useEffect, useState, useRef, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";

// @MUI IMPORTS
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Slide as SlideMUI } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { TransitionProps } from '@mui/material/transitions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import NavigateNextSharpIcon from '@mui/icons-material/NavigateNextSharp';
import NavigateBeforeSharpIcon from '@mui/icons-material/NavigateBeforeSharp';

// @THEME IMPORTS
import { CartItem } from "@reducer/cartReducer";
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import Magnifier from "react-magnifier";
import { useAppContext } from "../../contexts/app/AppContext";
import useWindowSize from "../../hooks/useWindowSize";
import CartService from "../../services/frontend/cart.service";
import Box from "../Box";
import FlexBox from "../FlexBox";
import Image from "../Image";
import { H1, H2, H3, SemiSpan, Small, Span, Tiny } from "../Typography";
import Avatar from "../avatar/Avatar";
import Button from "../buttons/Button";
import Grid from "../grid/Grid";
import BasicTable from "./BasicTable";
import StyledProductIntro from './ProductIntroStyle';


// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";



// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";
import "./styles.css"
import StyledSwiper from './SwiperStyle';

// import required modules
import { useSwipeable } from 'react-swipeable';
import { Link } from '@inertiajs/react';
import { AnimatePresence, motion, useIsPresent } from "framer-motion";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";




const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <SlideMUI direction="up" ref={ref} {...props} />;
});

export interface ProductIntroProps {
  product?: Array<T>,
  breadcrumbs?: Array<T>,
  imgUrl?: string[];
  title: string;
  price: number;
  initialPrice: number;
  tax_price: number;
  discount_percent: number;
  id?: string | number;
  sku?: string;
  description: string;
}
const cartService = new CartService()

const ProductIntro: React.memo<ProductIntroProps> = ({
  imgUrl,
  title,
  price,
  id,
  sku,
  product,
  auth,
  initialPrice,
  discount_percent,
  tax_price,
  breadcrumbs = null
}) => {

  const [swiperInstace, setSwiperInstance] = useState()
  const [tableState, setTableState] = useState({})
  const [selectedImage, setSelectedImage] = useState(0);
  const [colorImages, setColorImages] = useState([])
  const [color, setColor] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [open, setOpen] = useState(false);
  const [openQuantity, setOpenQuantity] = useState(false)
  const [openLogin, setOpenLogin] = useState(false)
  const [loadProgress, setLoadProgress] = useState(false)
  const [buttonClicks, setButtonClicks] = useState(0)
  const [group, setGroup] = useState(null)
  const [key, setKey] = useState(0)
  const [openTool, setOpenTool] = React.useState(false);
  const [updText, setUpdText] = useState(false)
  const [initialPricee, setInitialPricee] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [showInitialPrice, setShowInitialPrice] = useState(null);
  const [showDiscountPrice, setShowDiscountPrice] = useState(null);
  const [minPrice, setMinPrice] = useState(null);
  const [minDiscountPrice, setMinDiscountPrice] = useState(null);
  const [keyRender, setKeyRender] = useState<number>()

  const textSize = () => screenWidth > 1980 ? { fontSize: '0.8vw' } : {};
  const { state, dispatch } = useAppContext()
  const cartList: CartItem[] = state.cart.cartList
  const { t } = useTranslation()
  const { currencySymbol } = state.currency
  const { setOpenSnackCart, setOpenSnackRemove, setOpenSnackUpdate, setOpenSnackError, fixPrice } = useAppContext()
  const cartService = new CartService({
    setOpenSnackCart: setOpenSnackCart,
    setOpenSnackRemove: setOpenSnackRemove,
    setOpenSnackUpdate: setOpenSnackUpdate,
    setLoadProgress: setLoadProgress,
    setOpenSnackError: setOpenSnackError
  })
  const routerId = ''
  const screenWidth: number | null = useWindowSize()
  const cartImage = product.base_image.small_image_url

  const [imgsLoaded, setImgsLoaded] = useState(false)


  useEffect(() => {

    let pri;
    let discPri;

    if (product?.off == 0) {
      pri = product?.is_package === 1 || product.show_total_price === 0
        ? (parseFloat(product?.product_price)).toFixed(2)
        : (parseFloat(product?.price)).toFixed(2)


      discPri = product.is_package === 1 || product.show_total_price === 0
        ? (fixPrice(parseFloat(product.product_price_discount)))
        : (fixPrice(parseFloat(product.price)))
    } else {

      pri = product.is_package === 1 || product.show_total_price === 0
        ? (fixPrice(parseFloat(product.product_price)))
        : (fixPrice(parseFloat(product.initialPrice)))

      discPri = product.is_package === 1 || product.show_total_price === 0
        ? (fixPrice(parseFloat(product.product_price_discount)))
        : (fixPrice(parseFloat(product.price)))

    }

    setMinPrice(pri);
    setMinDiscountPrice(discPri);
    setShowInitialPrice(pri)
    setShowDiscountPrice(discPri)

  }, [])

  useEffect(() => {

    //console.log('product => ', product);

    if (!color) {

      if (minPrice && minDiscountPrice) {
        setShowInitialPrice(minPrice)
        setShowDiscountPrice(minDiscountPrice)
      }

      return;
    }

    let pri = product?.is_package === 1 || product.show_total_price === 0
      ? (parseFloat(initialPricee) / parseFloat(product?.package_quantity)).toFixed(2)
      : (parseFloat(initialPrice)).toFixed(2)


    let discPri = product.is_package === 1 || product.show_total_price === 0
      ? (fixPrice(parseFloat(discountPrice) / parseFloat(product?.package_quantity)))
      : (fixPrice(parseFloat(discountPrice)))


    setInitialPricee(pri)
    setDiscountPrice(discPri)
    setShowInitialPrice(pri)
    setShowDiscountPrice(discPri)

  }, [color])

  useLayoutEffect(() => {
    const loadImage = imageData => {
      return new Promise((resolve, reject) => {
        const img = new window.Image()
        img.src = imageData.url
        img.onload = () => resolve(imageData.url)
        img.onerror = err => reject(err)
      })
    }

    Promise.all(imgUrl.map((imageData: any) => loadImage(imageData)))
      .then(() => setImgsLoaded(true))
      //.then(() => console.log('loaded'))
      .catch(err => console.log("Failed to load images", err))
  }, [])

  useLayoutEffect(() => {
    setSelectedImage(0)
    setColorImages(getProductVariantImages(product, color))
      // setImgsLoaded(true);
  }, [colorImages, color])

  const getProductVariantImages = (product: any, colorCodeId: number) => {
    const variant = product.variants && product.variants.find((variant: any) => variant.color_code_id == colorCodeId)
    return variant ? variant.images : null
  }

  const handleClose = () => {
    setOpenSnack(false)
    setOpen(false);
    setOpenQuantity(false)
    setOpenLogin(false)
  }

  const handleImageClick = (ind: number) => () => {
    console.log('ind', ind);

    setSelectedImage(ind)
    swiperInstace.activeIndex(ind)
  }

  const handleArrowBack = () => {
    //setKeyRender((prevCount) => prevCount + 1);

    if (selectedImage !== 0) {
      setSelectedImage(prev => prev - 1)
      swiperInstace.slideTo(selectedImage - 1)
    } else if (selectedImage < 0) {
      setSelectedImage(0)
      swiperInstace.slideTo(selectedImage - 1)
    }
  }

  const handleArrowForward = () => {
    setKeyRender((prevCount) => prevCount + 1);

    if (selectedImage !== (colorImages?.length > 0 ? colorImages.length - 1 : imgUrl.length - 1)) {
      setSelectedImage(prev => prev + 1)
      swiperInstace.slideTo(selectedImage - 1)
    }
  }

  useEffect(() => {
    console.log('swiper active index', swiperInstace?.activeIndex);

  }, [swiperInstace?.activeIndex])


  const handleCartAmountChange = useCallback(

    (variantItems: any[]): () => Promise<void> => async () => {

      if (!auth) {
        setOpenLogin(true)
        return
      }

      if (color === null && group === null) {
        setOpen(true);
        return
      }

      if (buttonClicks < 1) {
        setOpenQuantity(true)
        return
      }

      setLoadProgress(true)

      // if (variantItems.length > 1) {

      //   let item = variantItems[0];

      //   variantItems = variantItems.slice(1);

      //   const cartItem = cartList.find(
      //     (itemCart) => itemCart.product_id === item.id
      //   );

      //   let payload = {
      //     parent_id: id,
      //     qty: item.qty,
      //     name: title,
      //     sku: sku,
      //     base_total_with_tax: item.price,
      //     base_price: item.price,
      //     total: item.price,
      //     price: item.price,
      //     //imgUrl: product.base_image['medium_image_url'] ? product.base_image['medium_image_url'] : product.base_image[0],
      //     imgUrl: colorImages?.length > 0 ? colorImages[0]?.url : product.base_image['medium_image_url'],
      //     id: cartItem ? cartItem.id : item.id,
      //     color_attr_id: item.color_attribute_id,
      //     color_code_id: item.color_code_id,
      //     size_attr_id: item.size_attribute_id,
      //     size_code_id: item.size_code_id,
      //     product_id: item.id,
      //     url_key: product.url_key,
      //     initialPrice: product.initialPrice,
      //     discount_percent: product.off,
      //     tax_price: product.tax_price,
      //     tax_percent: product.tax_percent,
      //     qty_step: product.qty_step
      //   }

      //   if (!cartItem) {
      //     await cartService.add(dispatch, payload)
      //   } else {
      //     await cartService.update(dispatch, payload)
      //   }

      // }


      let addProducts = [];

      variantItems.map(async (item: any) => {

        const cartItem = cartList.find(
          (itemCart) => itemCart.product_id === item.id
        )
        let payload = {
          parent_id: id,
          qty: item.qty,
          name: title,
          sku: sku,
          base_total_with_tax: item.price,
          base_price: item.price,
          total: item.price,
          price: item.price,
          imgUrl: colorImages?.length > 0 ? colorImages[0]?.url : product.base_image['medium_image_url'],
          id: cartItem ? cartItem.id : item.id,
          color_attr_id: item.color_attribute_id,
          color_code_id: item.color_code_id,
          size_attr_id: item.size_attribute_id,
          size_code_id: item.size_code_id,
          product_id: item.id,
          url_key: product.url_key,
          initialPrice: product.initialPrice,
          discount_percent: product.off,
          tax_price: product.tax_price,
          tax_percent: product.tax_percent,
          qty_step: product.qty_step
        }

        if (!cartItem) {
          //await cartService.add(dispatch, payload)
          addProducts.push(payload);
        } else {
          await cartService.update(dispatch, payload)
        }

      })

      await cartService.addMultiple(dispatch, addProducts)

      const qtygreaterzero = variantItems.some(item => item.qty > 0)

      if (qtygreaterzero) {
        setUpdText(true)
      } else setUpdText(false)

    }, [colorImages, buttonClicks, cartList])

  const handlers = useSwipeable({
    onSwipedRight: () => handleArrowBack(),
    onSwipedLeft: () => handleArrowForward(),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true
  })

  const prevRef = useRef<HTMLDivElement>(null)
  const nextRef = useRef<HTMLDivElement>(null)

  return (

    <StyledProductIntro length={imgUrl.length} overflow="visible">
      <Grid container justifyContent="center" spacing={16} className='product-intro-wrapper '>
        <Grid item md={6} xs={12} alignItems="center" className='prod-image-container' >
          <FlexBox className="prod-image-inner">
            {screenWidth < 915 && breadcrumbs &&
              <FlexBox flexDirection={'column'}>

                <FlexBox alignItems="center">
                    <Button
                        onClick={() => history.back()}
                        style={{
                            width: '10px',
                            height: '10px',
                            borderRadius: '8px',
                            padding: '11px',
                            marginRight: '12px',
                            marginLeft: '10px',
                        }}
                    >
                        <ChevronLeftIcon />
                    </Button>
                  <Breadcrumbs separator={<ArrowForwardIosIcon sx={{ color: 'white', width: '0.6em' }} />} aria-label="breadcrumb">
                    {
                      breadcrumbs.map((value: { title: string }, index: number) => {

                        /*if (index + 1 === breadcrumbs.length) {
                          return (
                            <Small key={index}>{value.title}</Small>
                          )
                        } else {
                          return (<Small key={index}>{value.title}</Small>)
                        }*/

                        if (index + 1 === breadcrumbs.length) {
                          return (
                            <Small key={index}>{value.title}</Small>
                          )
                        } else {

                          return (
                            <Link key={index} href={value.slug}><Small>{value.title}</Small></Link>
                          )
                        }

                        //return(<Link href={value.url}>{value.title} &gt; </Link>   )
                      })
                    }
                  </Breadcrumbs>
                </FlexBox>

                <H1 mb="1rem" className='title'>{title}</H1>

              </FlexBox>
            }
            <FlexBox className="img-container" width={'100%'} height='100%' justifyContent={screenWidth > 915 ? "flex-start" : 'center'} mb={screenWidth > 520 ? "20px" : '0px'}>

              {screenWidth > 620
                ?
                colorImages?.length > 0 ?
                  <Magnifier
                    src={colorImages[selectedImage]?.url}
                    width={'100%'}
                    mgWidth={400}
                    mgHeight={400}
                    mgShape="square"
                    zoomFactor={0.4}
                    mgBorderWidth={1}
                    mgTouchOffsetX={0}
                    mgShowOverflow={false}
                    alt={title}

                  />
                  : (imgsLoaded ?
                    <Magnifier
                      src={imgUrl[selectedImage]?.large_image_url}
                      width={'100%'}
                      mgWidth={400}
                      mgHeight={400}
                      mgShape="square"
                      zoomFactor={0.4}
                      mgBorderWidth={1}
                      mgTouchOffsetX={0}
                      mgShowOverflow={false}
                      alt={title}
                    /> :
                    <Skeleton variant="rectangular" width={'100%'}
                      height={screenWidth > 1980 ? '44.5vw' : screenWidth > 1070 ? '39.6vw' : 787} />
                  )
                : (

                  imgsLoaded ?
                    <motion.div
                      key={3}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{
                        opacity: { duration: 1 },

                      }}
                    >
                      <Image
                        className="main-image"
                        alt={title}
                        src={colorImages !== null && colorImages[selectedImage] ? colorImages[selectedImage]?.url : imgUrl[selectedImage]?.large_image_url}
                        {...handlers}
                      //src={imgUrl.length > 1 ? imgUrl[selectedImage].large_image_url : imgUrl[0]}
                      /> </motion.div> :
                    <Skeleton variant="rectangular" width={'100%'} height={'143vw'} />
                )
              }
            </FlexBox>

            {imgUrl?.length > 1 &&

              <FlexBox key={key} className="side-images-container">
                {screenWidth > 1070 && (imgsLoaded ?

                  <motion.div
                    key={1}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{
                      opacity: { duration: 0.3 },
                      height: { duration: 0.4 },
                    }}
                  >
                    <StyledSwiper>
                      <Swiper
                        onSwiper={(swiper) => setSwiperInstance(swiper)}

                        // navigation={true}
                        //  modules={[Navigation]}
                        slidesPerView={4}
                        spaceBetween={14}
                        direction={"vertical"}
                        onNavigationNext={handleArrowForward}
                        onNavigationPrev={handleArrowBack}

                      >

                        {colorImages?.length > 0 ?

                          colorImages.map((url: { url: any; }, ind: number) => (
                            selectedImage !== ind && (
                              <SwiperSlide
                                className="side-images"
                                onClick={handleImageClick(ind)}
                                index={ind}
                                key={ind}
                              >
                                <Avatar src={url.url} height='100%' width='100%' />

                              </SwiperSlide>
                            )

                          ))

                          :

                          imgUrl.map((url: { medium_image_url: any; }, ind: React.Key | null | undefined) => (
                            selectedImage !== ind && (
                              <SwiperSlide

                                onClick={handleImageClick(ind)}
                                index={ind}
                                key={ind}
                              >

                                <Avatar src={url.small_image_url} height='100%' width='100%' />

                              </SwiperSlide>
                            )
                          ))
                        }


                      </Swiper>

                      <button className="custom-prev absolute top-0" >
                          <KeyboardArrowUpIcon fontSize="large" sx={{ color: 'white', backgroundColor: '#21212180', borderRadius: '50%', }} onClick={handleArrowBack} />

                      </button>
                      <button className="custom-next absolute bottom-0" >
                        <KeyboardArrowDownIcon fontSize="large" sx={{ color: 'white', backgroundColor: '#21212180', borderRadius: '50%', }} onClick={handleArrowForward} />
                      </button>

                    </StyledSwiper>
                  </motion.div> :
                  <motion.div
                    key={2}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{
                      opacity: { duration: 0.3 },
                      height: { duration: 0.4 },
                    }}
                  >

                    <Stack spacing={1}>
                      <Skeleton variant="rectangular" width={'100%'} height={screenWidth < 1440 ? 123 : screenWidth < 1980 ? '9.5vw' : '10.8vw'} />
                      <Skeleton variant="rectangular" width={'100%'} height={screenWidth < 1440 ? 123 : screenWidth < 1980 ? '9.5vw' : '10.8vw'} />
                      <Skeleton variant="rectangular" width={'100%'} height={screenWidth < 1440 ? 123 : screenWidth < 1980 ? '9.5vw' : '10.8vw'} />
                      <Skeleton variant="rectangular" width={'100%'} height={screenWidth < 1440 ? 123 : screenWidth < 1980 ? '9.5vw' : '10.8vw'} />
                    </Stack>

                  </motion.div>)
                }
                {screenWidth <= 1070 &&

                  (imgsLoaded
                    ?
                          <>
                              <Swiper
                                  modules={[Navigation, Pagination]}
                                  //  pagination={{dynamicBullets: true}}
                                  onSwiper={(swiper) => setSwiperInstance(swiper)}

                                  slidesPerView={4}
                                  spaceBetween={2}
                                  direction='horizontal'
                                  onNavigationNext={handleArrowForward}
                                  onNavigationPrev={handleArrowBack}

                                  // navigation={{
                                  //   nextEl: '.custom-next',
                                  //   prevEl: '.custom-prev',
                                  // }}
                              >
                                  {colorImages?.length > 0 ?
                                      colorImages.map((url: { url: any; }, ind: React.Key | null | undefined) => (
                                          selectedImage !== ind && (
                                              <SwiperSlide
                                                  className="side-images"
                                                  onClick={handleImageClick(ind)}
                                                  index={ind}
                                                  key={ind}

                                              >
                                                  <Avatar src={url.url} height='100%' width='100%'/>
                                              </SwiperSlide>
                                          )
                                      ))

                                      :

                                      imgUrl.map((url: {
                                          medium_image_url: any;
                                      }, ind: React.Key | null | undefined) => (
                                          selectedImage !== ind && (
                                              <SwiperSlide
                                                  className="side-images"
                                                  onClick={handleImageClick(ind)}
                                                  index={ind}
                                                  key={ind}
                                              >
                                                  <Avatar src={url.medium_image_url} height='100%' width='100%'/>
                                              </SwiperSlide>
                                          )
                                      ))

                                  }
                              </Swiper>
                              <button className="custom-prev">
                                  <NavigateBeforeSharpIcon fontSize="large" sx={{
                                      color: 'white',
                                      backgroundColor: '#21212180',
                                      borderRadius: '50%',
                                  }} onClick={handleArrowBack}/>
                              </button>
                              <button className="custom-next">
                                  <NavigateNextSharpIcon fontSize="large" sx={{
                                      color: 'white',
                                      backgroundColor: '#21212180',
                                      borderRadius: '50%',
                                  }} onClick={handleArrowForward}/>
                              </button>
                          </> :
                          <Stack sx={{margin: 'auto'}} direction="row" spacing={0.3}>
                              {/* For variant="text", adjust the height via font-size */}
                              <Skeleton variant="rectangular" width={screenWidth > 620 ? 126 : '24vw'} height={164}/>
                              <Skeleton variant="rectangular" width={screenWidth > 620 ? 126 : '24vw'} height={164}/>
                              <Skeleton variant="rectangular" width={screenWidth > 620 ? 126 : '24vw'} height={164}/>
                              <Skeleton variant="rectangular" width={screenWidth > 620 ? 126 : '24vw'} height={164} />
                    </Stack>
                  )}
              </FlexBox>
            }

          </FlexBox>

        </Grid>

        <Grid item xl={6} xs={12} alignItems="center" className='product-intro-info-wrapper'>

          <FlexBox justifyContent={'space-between'} className='product-intro-info'>

            <FlexBox flexDirection={'column'} flexBasis='60%'>

              {screenWidth > 914 &&
                <>

                  <FlexBox alignItems="center">
                    <Breadcrumbs separator={<ArrowForwardIosIcon fontSize="5px" sx={{ color: 'white' }} />} aria-label="breadcrumb">

                      {

                        /*breadcrumbs.map((value, ind) => (
                          <Small key={ind}>{value.title}</Small>
                          //return(<Link href={value.url}>{value.title} &gt; </Link>   )
                        ))*/


                        breadcrumbs.map((value: { title: string }, index: number) => {


                          if (index + 1 === breadcrumbs.length) {
                            return (
                              <Small key={index}>{value.title}</Small>
                            )
                          } else {

                            return (
                              <Link key={index} href={value.slug}><Small>{value.title}</Small></Link>
                            )
                          }
                          //return(<Link href={value.url}>{value.title} &gt; </Link>   )
                        })
                      }

                    </Breadcrumbs>

                  </FlexBox>

                  <H1 mb="1rem" className='title'>{title}</H1>
                </>

              }
              {product.details &&
                <Box mb="12px" className="description">
                  <SemiSpan ><div dangerouslySetInnerHTML={{ __html: product.details }}></div></SemiSpan>
                </Box>}
            </FlexBox>

            {state.auth.isAuth &&
              <FlexBox flexDirection='column' className='price-wrapper'>

                {product?.off == 0 &&
                  <>

                    <H1 mb="4px" lineHeight="1" className='price '>

                      {showInitialPrice + currencySymbol}

                    </H1>

                    <Tiny className='fpa'>{t('product.vat')}</Tiny>
                  </>
                }

                {product?.off > 0 &&
                  <>
                    <Box>
                      <FlexBox justifyContent='flex-end'>
                        <Box position='relative' >
                          <H1 mb="4px" lineHeight="1" className='price initial-price'>

                            {
                              showInitialPrice + currencySymbol
                            }
                          </H1>
                        </Box>
                        <H1 ml={3} mb="4px" lineHeight="1" className='price '>

                          {showDiscountPrice + currencySymbol}
                        </H1>
                      </FlexBox>
                      <Tiny className='fpa'>{t('product.vat')}</Tiny>
                    </Box>
                    {screenWidth > 768

                      ? <Tooltip title={t('product.discount')}>
                        <Box>
                          <H3 className='discount'> -{product?.off}%</H3>
                        </Box>
                      </Tooltip>
                      :

                      <ClickAwayListener onClickAway={() => setOpenTool(false)}>
                        <Tooltip
                          onClose={() => setOpenTool(false)}
                          open={openTool}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title={t('product.discount')}
                          sx={{ textAlign: 'left' }}
                        >
                          <Box>
                            <H3 onClick={() => setOpenTool(true)} className='discount'> -{product?.off}%</H3>
                          </Box>
                        </Tooltip>
                      </ClickAwayListener>
                    }

                  </>
                }
              </FlexBox>}
          </FlexBox>

          <BasicTable updText={updText} setUpdText={setUpdText} group={group} setGroup={setGroup} setOpen={setOpen} loadProgress={loadProgress} setLoadProgress={setLoadProgress} setKey={setKey}
            setButtonClicks={setButtonClicks} color={color} setColor={setColor} auth={state.auth.isAuth}
            product={product} currencySymbol={currencySymbol} handleCartAmountChange={handleCartAmountChange}
            setInitialPrice={setInitialPricee} setDiscountPrice={setDiscountPrice}
          />
          {/* <DataTable/> */}

        </Grid>
      </Grid>

      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={Transition}
        maxWidth='md'
      >
        <DialogContent sx={{ width: '99%' }}>
          <DialogContentText sx={textSize} className="alert-dialog" id="alert-dialog-slide-description">
            {t("dialog.choose")} <Span color='black' fontWeight={700}>{t("dialog.color")}</Span> {t("dialog.beforeadd")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button backgroundColor='black' onClick={handleClose}>{t("ok")}</Button>
        </DialogActions>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 2,
            top: 2,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Dialog
        open={openQuantity}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={Transition}
        maxWidth='md'
      >
        <DialogContent sx={{ width: '95%' }}>
          <DialogContentText sx={textSize} className="alert-dialog" id="alert-dialog-slide-description">
            {t("dialog.choose1")}  <Span color='black' fontWeight={700}>{t("dialog.quantity")}</Span> {t("dialog.beforeadd")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button backgroundColor='black' onClick={handleClose}>Εντάξει</Button>

        </DialogActions>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 2,
            top: 2,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Dialog
        open={openLogin}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={Transition}
        maxWidth='md'
      >
        <DialogContent sx={{ width: '95%' }}>
          <DialogContentText sx={textSize} className="alert-dialog" id="alert-dialog-slide-description">
            {t("dialog.please")}
            <Link href='/customer/login'>
              <Span color='black' fontWeight={700}> {t("dialog.login")} </Span>
            </Link>
            {t("dialog.addlogin")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link href='/customer/register'>
            <Button backgroundColor='black' onClick={handleClose}>{t("dialog.register")}</Button>
          </Link>
          <Link href='/customer/login'>
            <Button backgroundColor='black' onClick={handleClose}>{t("dialog.login1")}</Button>
          </Link>
        </DialogActions>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 2,
            top: 2,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

    </StyledProductIntro >

  );
};



export default ProductIntro;
