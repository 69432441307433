export const deviceSize = {
  xs: 445,
  s: 530,
  sm: 768,
  smd: 900,
  md: 1024,
  lg: 1440,
  xlg: 1980
};

export const layoutConstant = {
  grocerySidenavWidth: "280px",
  containerWidth: "1200px",
  mobileNavHeight: "64px",
  headerHeight: "80px",
  mobileHeaderHeight: "90px",
};
