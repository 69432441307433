import styled from "styled-components";
import Box from "../Box";
import FlexBox from "../FlexBox";
import { deviceSize } from "../../utils/constants";

const ProductIntroStyle = styled(Box) <{ length: number }>`



    .back-button{
        width:30%;
    }

    .MuiAlert-icon{
        color : royalblue!important;
    }

    .swiper-pagination-bullet {
        
        background-color:white;
        width : 12px;
        height : 12px;
        border : solid 1px black;
    }

    .prod-image-container{
        padding-left : 0;
        padding-right : 0;
        padding-bottom : 0;
        width:47%;
        min-width : 435px;
    }

    .initial-price{
        color : #707070;
    }

    .initial-price:after {
        content: "";
        border-top: 2px solid;
        border-color: #707070;
        position: absolute;
        right: 0;
        left: 0;
        top: 50%;
        transform: rotate(-10deg);
    }

    .prod-image-inner {
        gap:5px;
        position : relative;
    }

    .product-intro-wrapper{
        justify-content: flex-start;
        flex-wrap : nowrap;
    }

    .description{
        display: flex;
        flex-direction : column;

        ..span:first-child{
            text-decoration : underline;
        }
    }

    .title{
        font-weight:500;
        font-size : 1.6vw; 
      
    }

    .price{
        font-weight:600;
       
    }

    .fpa{
        white-space: nowrap;
    }

    .discount{
        border : solid 1px #bebcbc;
        border-radius : 6px;
        font-weight:600;
        padding:0px;
        width:45%;
        max-height : 39px;
        max-width: 92px;
        text-align : center;
        float : right ;
        margin-top : 10px
       
    }

    .product-intro-info-wrapper{
        display:flex;
        flex-direction : column;
        justify-content : flex-start;
        
        padding-right : 0;
        padding-bottom : 0;
       
    }

    .product-intro-info {
        width:100%;
    }

    .side-images-container{
        flex-direction :column;
       // gap : 12px;
 
    }

    .add-to-cart-button{
        width : 100%;
        background-color : black;
        border : solid 1px white;
        font-size : 20px;
        font-weight :300;
        color : white;
        margin-top : 1.3rem;
    }

    .add-to-cart-button:focus {
        box-shadow : none;
    }

    .image-holder{
        position : unset;
    }

    .product-intro-wrapper{
        gap:3rem;
        
    }

    @media only screen and (min-width: ${deviceSize.lg}px) and (max-width: ${deviceSize.xlg}px) {
        .product-intro-info-wrapper{
            width : 55%;
        }
      }

    @media only screen and (max-width : ${deviceSize.xlg}px){
        .prod-image-container{
            width : 41.8%;
        }
    }

    @media only screen and (max-width: 1070px) {

        .swiper-button-next:after , .swiper-button-prev:after {
            content : unset!important;
        }

        
            .custom-next{
              position: absolute;
              top: 43%;
              right : 0%;
            }
          
            .custom-prev{
              position: absolute;
              top: 43%;
              left: 0%;
            }
     

        .prod-image-inner{
            flex-direction: column;
        }

        .prod-image-container{
            min-width: 525px; 
        }

        .side-images-container{
            flex-direction :row;
            height : 170px;
        }

        .carousel__back-button{
            position : absolute;
            top:40%;
            right : unset;
        }
    
        .carousel__next-button{
            position: absolute;
            right: 0px;
            top: 40%;
            bottom : unset;
        }

        .carousel::after{
            background:unset;            
        }

        .product-intro-info-wrapper{
            padding-left : 0px;
        }

        .carousel__inner-slide img {
            padding : 2px;
        }
    
    }

    @media only

    @media only screen and (min-width: 400px) {
        .prod-image-container{
          
            min-width:435px;
        }
    }

    @media only screen and (min-width: 1071px) {
        
        .product-intro-wrapper{
            min-height : 48vw;
        }

        .prod-image-inner{
            min-width : 30vw;
        }

        .side-images-container{
            width:25%;   
        }

        .MuiDialogContent-root {
            padding-left : 1rem!important;
        }
    
    }

    @media only screen and (max-width: 915px) {
        .product-intro-wrapper{
            flex-direction : column;
            align-items : center;
        }

        .title{
            font-size:22px;
        }

        .product-intro-info{
            flex-direction: column-reverse;
        }

        .prod-image-container{
            padding-bottom:5px;
        }

        .discount{
            float:unset;
            width:80px;
        }

        .description{
            margin-top:0;
            margin-bottom :0;
        }

        .price-wrapper{
            flex-direction : row-reverse;
            justify-content: space-between;
            align-items: baseline
        }

        .title{
            margin-top:0.7rem;
            margin-bottom:0.7rem;
        }

        .description{
            padding-top : 2rem;
            padding-bottom : 2rem;
            
        }

        .side-images{
            height : 200px;
            
        }

        

    }

    .carousel{
        width:100%;
        position: relative;
       
    }
    
    .swiper-button-next{
        bottom : 8px;
    }
    
 .swiper-button-next:hover ,.swiper-button-prev:hover  {
    background-color: rgba(0 ,0 ,0 ,0.2);
    padding : 2px;
    border-radius : 3px;
  }

    @media only screen and (max-width: 530px) {
        .prod-image-container{
            min-width :unset;
            width:auto;
            padding-top:1rem;
        }

        .product-intro-wrapper{
            gap : 1rem;
        }

        

        .img-container{
            min-height:147vw;
        }

        
        .side-images{
            height : 40vw;
            
        }

        .product-intro-info-wrapper{
            padding-top:0;
        }
        
        .price{
            
            margin-bottom:-4px;
        }

        .discount{
            font-size:18px;
            width:56px;
        }

        .description{
            padding : 5px 0px;
        }

        .fpa{
            font-size:8px;
            font-weight:100;
        }
        
        .image-holder{
            height : 150px;
        }

        .swiper{
            position : unset;
        }

        .swiper-button-prev , .swiper-button-next{
            top : 25%;
            display : flex!important;
        }
    }

    .breadcrump{
        font-weight:200;
    }

    @media only screen and (min-width: 1980px) {

        .back-button{
            font-size : 0.7vw;
        }
        .MuiBreadcrumbs-li{
            span{
                font-size : 0.6vw;
            }
        }

        .MuiSvgIcon-root{
            width : 1vw;
            height : 1vw;
        }

        .carousel {
            .MuiSvgIcon-root{
                width : 2vw;
                height : 2vw;
            }
        }

        .MuiDialogContentText-root {
            font-size : 0.8vw;
        }

        .description {
            padding-top : 1vw;
            padding-bottom : 1vw;

            span{
                font-size : 0.8vw;
            }
        }

        .price{
            font-size:2vw;
        }

        .fpa{
            font-size : 0.5vw;
        }

        .discount{
            max-height:unset;
            max-width:100%;
            font-size : 1.1vw;
        }

        .add-to-cart-button{
            height : unset;
            font-size : 1vw;
            div{
                /width:1vw;
                height:1.1vw;
            }
           
        }

        .prod-image-inner{
            gap:1%;
        }

        .side-images-container{
            width:25.1%;
        }
    }

`



export default ProductIntroStyle