
import * as React from 'react';
//const CHANGE_AUTH_STATE = "CHANGE_AUTH_STATE";

export const currencyInitialState = {
  currencySymbol:"€"

};

export type currencyActionType = { currency: {type: ''; payload: {} }};
export type currencyStateType = typeof currencyInitialState;




export const currencyReducer: React.Reducer<currencyStateType, currencyActionType> = (
  state: currencyStateType,
  action: currencyActionType
) => {
  switch (action.type) {
    
    case "CHANCE_CURRENCY":
     
      return {
        ...state,
        currencySymbol: action.payload.currencySymbol,
      };

    

    default: {
    }
  }
};
