export const layoutInitialState = {
  isHeaderFixed: false,
};

export type layoutStateType = typeof layoutInitialState;
export type layoutActionType = { layout: { type: ""; payload: string } };

export const layoutReducer: React.Reducer<layoutStateType, layoutActionType> = (
  state: layoutStateType,
  action: layoutActionType
) => {
  switch (action.type) {
    case "TOGGLE_HEADER":
      return {
        ...state,
        isHeaderFixed: action.payload,
      };

    case "FAVICON":

      

      return {
        ...state,
        isHeaderFixed: true,
        favicon: action.payload
      };

    default: {
    }
  }
};
