import React, { useState } from "react";
import StyledSideMenu from './SideMenuStyle'
import useWindowSize from "../../hooks/useWindowSize";
import AppContext, { useAppContext } from "../../contexts/app/AppContext";
import Divider2 from "../home-1/Divider2";
import { Link } from '@inertiajs/react';
import { H3 } from "../Typography";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { usePage } from '@inertiajs/react'
import { useTranslation } from "react-i18next";

type SideMenuProps = {
  toggleSidenav2?: () => void;
}



const SideMenu: React.memo<SideMenuProps> = () => {

  //const { categories, setCategories } = useAppContext();
  const { categories } = useAppContext();
  //const { categories } = usePage().props
  //setCategories(categoriess)
  const { t } = useTranslation()
  const screenWidth = useWindowSize()
  const [categoryStack, setCategoryStack] = useState<any[]>([])


  const handleCategoryClick = (category) => {

    if (category.children && category.children.length > 0) {
      setCategoryStack([...categoryStack, category])
    }
  }

  const setID = () => {
    //localStorage.setItem('ID', JSON.stringify(null))
    localStorage.removeItem('ID');
    localStorage.removeItem('pageNumber')
  }

  const handleBackButtonClick = () => {
    setCategoryStack(categoryStack.slice(0, categoryStack.length - 1));
  }

  const currentCategory = categoryStack.length > 0 ? categoryStack[categoryStack.length - 1] : null

  return (
    <StyledSideMenu>
      {!currentCategory && <Link onClick={setID} className='menu-item-name hover-bg' href='/'><H3 className='home-item-menu' fontWeight={600}>{t("menu.home")}</H3></Link>}
      {!currentCategory && <Divider2 />}
      <ul>
        {currentCategory ? (
          <>
            <li>
              <button onClick={handleBackButtonClick}><NavigateBeforeIcon fontSize="large" sx={{ color: 'white' }} /></button>
              {currentCategory && (
                <span className="parent-category-label slide-in">
                  {currentCategory.name}
                </span>)}
            </li>
            {currentCategory.children.map((child) => (
              <><li key={child.name} className="slide-in" style={{ fontWeight: 100 }}>
                {child.children && child.children.length > 0 ? (
                  <>
                    <Link onClick={setID} className='menu-item-name' href={`/${child.slug}`}>{child.name}</Link>
                    <button onClick={() => handleCategoryClick(child)}>
                      <NavigateNextIcon sx={{ color: 'white' }} />
                    </button>
                  </>
                ) : (
                  <Link onClick={setID} href={`/${child.slug}`} className='menu-item-name'> {child.name}</Link>
                )}
              </li><Divider2 /></>
            ))}
          </>
        ) : (

          categories && categories.map((category) => (

            <li key={category.name} style={{ fontWeight: 600 }}>
              {category.children && category.children.length > 0 ? (
                <>
                  <Link onClick={setID} className='menu-item-name' href={`/${category.slug}`}>{category.name}</Link>
                  <button onClick={() => handleCategoryClick(category)}>
                    <NavigateNextIcon fontSize="large" sx={{ color: 'white' }} />
                  </button>
                </>
              ) : (
                <Link onClick={setID} className='menu-item-name' href={`/${category.slug}`}>{category.name}</Link>
              )}
              <Divider2 />
            </li>
          ))
        )}
      </ul>
      {!currentCategory && <Link className="hover-bg menu-item-name" href='/contact'><H3 fontWeight={600}>{t("menu.contact")}</H3></Link>}
    </StyledSideMenu>
  )
}

SideMenu.defaultProps = {
  toggleSidenav2: () => { },
};

export default SideMenu

