
import * as React from 'react';
//const CHANGE_AUTH_STATE = "CHANGE_AUTH_STATE";
const RESTORE_TOKEN = "RESTORE_TOKEN";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

export const authInitialState = {
    isLoading: true,
    userToken: null,
    isAuth:false,
    canPost:true,
    customer:"",
};

export type authActionType = { auth: {type: ''; payload: {} }};
export type authStateType = typeof authInitialState;




export const authReducer: React.Reducer<authStateType, authActionType> = (
  state: authStateType,
  action: authActionType
) => {
  switch (action.type) {
    
    case "SIGN_IN":
    
      localStorage.setItem("login", 1);

      return {
        ...state,
        isAuth: true,
        userToken: action.payload.token,
        customer:action.payload.customer,
        canPost:false
      };

    case "SIGN_OUT":
      localStorage.removeItem('login');

      return {
        ...state,
        isAuth: false,
        userToken: null,
        canPost:false
      }

    default: {
    }
  }
};
