import ProductIntro from "../products/ProductIntro";
import { useAppContext } from "../../contexts/app/AppContext";
//import { CartItem } from "@reducer/cartReducer";
import React, { Fragment, useCallback, useLayoutEffect, useState } from "react";
import { CSSProperties } from "styled-components";
import Box from "../Box";
import Card, { CardProps } from "../Card";
import FlexBox from "../FlexBox";
import Icon from "../icon/Icon";
import Modal from "../modal/Modal";
import { H3, SemiSpan, Small, Span } from "../Typography";
import { StyledProductCard1 } from "./ProductCardStyle";
import { Link } from '@inertiajs/react'
//import { Link } from '@inertiajs/inertia-react'
import CartService from "../../services/frontend/cart.service";
import useWindowSize from "../../hooks/useWindowSize";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Fade from 'react-reveal/Fade';
import { isIOS } from "react-device-detect";
import { isWindows } from "react-device-detect";
import Skeleton from '@mui/material/Skeleton';
import DelayedInertiaLink from "../nav-link/DelayedInertiaLink";
import { router } from '@inertiajs/react'
import { useTranslation } from "react-i18next";

export interface ProductCard1Props extends CardProps {
  className?: string;
  style?: CSSProperties;
  imgUrl?: string;
  imgKey?: string;
  title?: string;
  price?: number;
  off?: number;
  rating?: number;
  id?: string | number;
  sku?: string;

}

const ProductCard1: React.memo<ProductCard1Props> = ({
  id,
  imgUrl,
  title,
  price,
  off,
  urlKey,
  rating,
  page,
  variants,
  sku,
  attributes,
  initialPrice,
  setShowPage,
  searchBox=false,
  ...props
}) => {

  const [open, setOpen] = useState(false)
  const cartService = new CartService()
  const screenWidth = useWindowSize()
  const { state, dispatch } = useAppContext()
  const { auth, currency } = state
  const cartItem: CartItem = state.cart.cartList.find((item: string) => item.id === id)
  const toggleDialog = useCallback(() => {
    setOpen((open) => !open)
  }, [])
  const [skeleton, setSkeleton] = useState(true)
  const { t } = useTranslation()
  const handleCartAmountChange = useCallback(
    (amount) => () => {
      /*dispatch(
        {
          'cart':
          {
            type: "CHANGE_CART_AMOUNT",
            payload: {
              name: title,
              qty: amount,
              price,
              imgUrl,
              id,
            },
          }
        }
      );*/

      let payload = {
        qty: amount,
        name: title,
        sku: sku,
        base_total_with_tax: price,
        base_price: price,
        total: price,
        price,
        id: id,
        product_id: id,
      }

      if (!cartItem) {
        cartService.add(dispatch, payload)

      } else {
        cartService.update(dispatch, payload)

      }

    },
    []
  )

    // Step 1: Extract unique color swatch values and color images from variants
    const colorsData = variants.reduce((acc, variant) => {
        const { color_swatch_value, color_image } = variant;
        if (color_swatch_value && !acc.find(item => item.swatch === color_swatch_value)) {
            acc.push({ swatch: color_swatch_value, image: color_image });
        }
        return acc;
    }, []);

    // Step 2: Function to check color availability
    const checkColorAvailability = (swatch) => {
        return variants.some(variant => variant.color_swatch_value === swatch && variant.quantity > 0);
    };


    const attributeSizes: string[] = []



  if (attributes && attributes.size) {
    attributes.size.map((sizes: string) => {
      attributeSizes.push(sizes)
    })
  }

  const uniqueSizes: string[] = attributeSizes?.filter((size, index, array) => {
    return !array.slice(0, index).includes(size);
  })

    // Step 3: Prepare the colors array with availability and image information
    const colors = colorsData.map(({ swatch, image }) => ({
        swatch,
        image,
        available: checkColorAvailability(swatch),
    }));


  const checkSize = (size: any) => {
    for (let i = 0; i < variants?.length; i++) {
      if (variants[i].size_label === size && variants[i].quantity > 0) {
        return true
      }
    }
    return false
  }

  const sizes = uniqueSizes.map((size: any) => {
    return {
      size: size,
      quantity: checkSize(size)
    }
  })

  const clickHandle = (event, url) => {



    setShowPage(false)
    // Prevent the default behavior of the anchor tag
    event.preventDefault();

    // Manually trigger the Inertia.js navigation
    router.visit(url);

  }

    const colorSize = screenWidth < 1020 ? { width: '15px', height: '15px', borderRadius: '5px',  margin: '2px' } : { width: '21px', height: '21px', borderRadius: '7px',  margin: '5px' };

    const Availabletext = screenWidth < 521 ? { marginBottom: '4%', } : { marginBottom: '1%', };
    // {screenWidth < 521 ? 'flex-end' : 'flex-end'}
  // const sizes = [
  //   { size: 'S', quantity: false },
  //   { size: 'M', quantity: true },
  //   { size: 'L', quantity: false },
  //   { size: 'XL', quantity: false },
  //   { size: 'XXL', quantity: true }
  // ]

  return (
    <StyledProductCard1 isIOS={isIOS} {...props} >

      <Fade >

        <div className="image-holder">
          {off > 0 && <Box>
            <Span className='discount-badge'>- {off}%</Span>
          </Box>
          }


          {
            !searchBox &&
            <Link
            onClick={(e) => clickHandle(e, `/${urlKey}`)}
            href={`/${urlKey}`}
            //href={`/${urlKey}`}
            className={"flex items-center pb-4 focus:text-indigo-700 focus:outline-none"}
          >

            <LazyLoadImage
              beforeLoad={() => setSkeleton(false)}
              visibleByDefault={true}
              delayTime={100}
              alt={title}
              height='100%'
              src={imgUrl} // use normal <img> attributes as props
              width='100%'
              placeholder={<Skeleton sx={{ background: '#000' }} variant="rectangular" width={210} height={500} />}
            />



          </Link>
          }

          {
            searchBox &&
            <Link

            href={`/${urlKey}`}
            className={"flex items-center pb-4 focus:text-indigo-700 focus:outline-none"}
          >

            <LazyLoadImage
              beforeLoad={() => setSkeleton(false)}
              visibleByDefault={true}
              delayTime={100}
              alt={title}
              height='100%'
              src={imgUrl} // use normal <img> attributes as props
              width='100%'
              placeholder={<Skeleton sx={{ background: '#000' }} variant="rectangular" width={210} height={500} />}
            />



          </Link>
          }

        </div>
        <Box className="details" p={0} >
          <FlexBox margin='auto'>
            <Box flex="1 1 0" minWidth="0px" >
              <Link
                onClick={(e) => clickHandle(e, `/${urlKey}`)}
                href={`/${urlKey}`}
                className="flex items-center"
              >
                <H3
                  className="title"
                  textAlign="left"
                  fontWeight="600"
                  color="white"
                  mt={'0px!important'}
                  // mb={'10px!important'}
                  // minHeight='61px'
                  position='relative'
                >
                  {title}
                </H3>
              </Link>
              {auth.isAuth && (
                <Box position='relative'>
                  <SemiSpan className='price'>
                    {(price) + ' ' + currency.currencySymbol}
                  </SemiSpan>

                  {off > 0 && (
                    <SemiSpan ml={3} className='price initial-price'>
                      {(initialPrice) + ' ' + currency.currencySymbol}
                    </SemiSpan>
                  )}
                </Box>
              )}
                {/*<SemiSpan className='diat' style={{ marginRight: '10px',  display: screenWidth < 521 ? 'block' : 'none' }}>*/}
                {/*    {t("product.available")} &nbsp;:*/}
                {/*</SemiSpan>*/}
                {/* FlexBox for colors */}
                <FlexBox
                    position='relative'
                    justifyContent='space-between'
                    alignItems='center'
                    className="color-availability"
                    flexWrap='wrap'
                >

                    <div
                        className='availability-label'
                        style={{
                            width: 'auto',
                            ...Availabletext
                        }}
                    >
                        <SemiSpan className='diat' style={{ textAlign: 'left' }}>
                            {t("product.available")} &nbsp;:
                        </SemiSpan>
                    </div>


                    <FlexBox
                        className="colors-wrapper"
                        flexWrap='wrap'
                        justifyContent='flex-end'
                        alignItems='center'
                        style={{
                            flexGrow: 1,
                        }}
                    >
                        {colors.slice(0, 5).map((element, i) => ( // Modified line
                            <Small
                                alt={"cover image"}
                                key={i}
                                textAlign='center'
                                border={'solid 1px white'}
                                className={`color ${element.available ? 'color-available-class' : 'color-unavailable-class'}`}
                                style={{
                                    backgroundImage: element.image ? `url(${element.image})` : 'none',
                                    backgroundColor: element.image ? 'none' : element.swatch,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    ...colorSize, // Apply dynamic size based on screen width
                                }}
                            />
                        ))}
                    </FlexBox>
                </FlexBox>


                {/* FlexBox for sizes, justify to the start on screens less than 521px */}
                <FlexBox position='relative' mt={1} justifyContent={screenWidth < 521 ? 'flex-end' : 'flex-end'} className="size-availability" width={screenWidth < 768 ? 170 : 'auto'}>
                    {/* Sizes wrapper */}
                    <FlexBox className="sizes-wrapper" flexWrap='wrap' justifyContent='flex-end'>
                        {sizes.map((element, i) => (
                            <Small textAlign='center' borderRadius={5} border={'solid 1px white'} key={i}
                                   className={`size ${element.quantity ? 'white-bg' : 'white-bg'}`}
                                   style={{ margin: '4px' }}
                            >
                                {element.size}
                            </Small>
                        ))}
                    </FlexBox>
                </FlexBox>



            </Box>
          </FlexBox>
        </Box >

        <Modal open={open} onClose={toggleDialog}>
          <Card p="1rem" position="relative">
            <ProductIntro imgUrl={[imgUrl]} title={title} price={price} id={id} sku={sku} />
            <Box
              position="absolute"
              top="0.75rem"
              right="0.75rem"
              cursor="pointer"
            >
              <Icon
                className="close"
                color="primary"
                variant="small"
                onClick={toggleDialog}
              >
                close
              </Icon>
            </Box>
          </Card>
        </Modal>
      </Fade>
    </StyledProductCard1 >
  )
}

export default ProductCard1;
