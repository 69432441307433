import styled from "styled-components";
import Box from "../Box";

const StyledSizeGuide = styled(Box)`
 width : 100%;

 @media only screen and (min-width : 1980px){
   button{
      padding : 0.7vw;
      font-size : 0.7vw;
   }
 }

`
export default StyledSizeGuide