import React from "react";
import { useAppContext } from "../../contexts/app/AppContext";
import Image from "../Image";
import Button from "../buttons/Button";
import StyledSizeGuide from "./SizeGuideStyle";
import { useTranslation } from "react-i18next";
// MUI  Modal components
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';


//Mui Table Components
import StraightenIcon from '@mui/icons-material/Straighten';

const rows = [
    ['2', 159, 23],
    ['4', 237, 23],
    ['6', 262, 23],
    ['8', 302, 23],
    ['10', 36, 23],
];

const columns = [
    'Ηλικία',
    'Ύψος',
    'Mέγεθος'
]


const SizeGuide = ({ sizeChart = null }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { logo } = useAppContext()
    const { t } = useTranslation()

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'rgb(16 15 15 / 95%)',
        border: '2px solid #000',
        borderRadius: 2,
        boxShadow: 24,
        p: 1,
    }

    return (
        <StyledSizeGuide >
            <Button onClick={handleOpen}> {t("product.sizeguide")} <StraightenIcon sx={{ marginLeft: 1 }} /> </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        {sizeChart ? <Image src={sizeChart} margin='auto' alt="logo" /> : null}
                    </Box>
                </Fade>
            </Modal>

        </StyledSizeGuide>

    )
}

export default SizeGuide