import styled from "styled-components";
import Box from "../Box";

const StyledSideMenu = styled(Box)`
     font-size : 20px; 
     color : white;
     margin-top : 5rem;
     margin-left : 3rem;
     line-height : 2.5;
     

     li , h3 {
      transition : 0.5s ease;
      
     }

     li > a{
      padding : 5px;
     }

     li:hover , h3:hover{
      background-color : black;
     }

     svg{
      margin-bottom : 3px;
      transition : 0.2s ease;
     }

     svg:hover {
      transform : scale(1.3)
     }
     
    .parent-category-label {
        font-weight : 600;
    }

  
  
  .slide-in {
    animation-name: slide-in;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
  }
  
  .slide-out {
    animation-name: slide-out;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes slide-in {
    from {
      transform: translateX(-10%);
      opacity : 0.2;
      
    }
    to {
      transform: translateX(0%);
      
    }
  }
  
  @keyframes slide-out {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  @media only screen and (min-width : 1980px){
    .menu-item-name, .menu-item-name h3 , .home-item-menu , .parent-category-label{
      font-size : 1vw;
    } 
  }

  @media only screen and (max-height : 450px){
    margin-top : 0;
  }

  
`

export default StyledSideMenu