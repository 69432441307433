import systemCss from "@styled-system/css";
import { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { color, compose, space, SpaceProps } from "styled-system";
import { convertHexToRGB } from "../../utils/utils";
import { TextFieldProps } from "./TextField";

export const SyledTextField = styled.input<
  InputHTMLAttributes<HTMLInputElement> & TextFieldProps
>(
  (props) =>
    systemCss({
      padding: "8px 12px",
      height: "69px",
      fontSize: "inherit",
      color: "white",
      borderRadius: 15,
      backgroundColor: '#FFFFFF0F',
      border: "1px solid transparent",
      borderColor: 'transparent',
      width: props.fullwidth ? "100%" : "inherit",
      outline: "none",
      fontFamily: "inherit",
      marginTop : '1rem',

      "&:hover": {
        borderColor: "gray.500",
      },
      "&:focus": {
        borderColor: "gray.500",
        boxShadow: 'unset'
      },
    }),
  compose(color)
);

export const TextFieldWrapper = styled.div<TextFieldProps & SpaceProps>(
  (props) =>
    systemCss({
      position: "relative",
      width: props.fullwidth ? "100%" : "inherit",

      label: {
        display: "block",
        marginBottom: '-35px',
        fontSize: '10px',
        color: '#9C9C9C',
        marginLeft: '13px',
      
      },

      small: {
        display: "block",
        color: "royalblue",
        marginTop: "0.25rem",
        marginLeft: "0.25rem",
      },

      ".end-adornment": {
        position: "absolute",
        top: "60%",
        transform: "translateY(-50%)",
        right: "0.25rem",
      },
    }),
  compose(color, space)
);
