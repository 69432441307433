import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useAppContext } from '../../contexts/app/AppContext';
import { useTranslation } from 'react-i18next';

// MUI Components
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import useWindowSize from '../../hooks/useWindowSize';
import AxiosService from '../../services/axios.service';
import Box from '../Box';
import FlexBox from '../FlexBox';
import Image from '../Image';
import Typography, { Span } from '../Typography';
import Button from '../buttons/Button';
import Icon from '../icon/Icon';
import StyledBasicTable, { StyledAddButton, StyledLink, StyledQuantityButtons } from './BasicTableStyle';
import ColorSelect from './ColorSelect';
import SizeGuide from './SizeGuide';

import { animated, useSpring } from '@react-spring/web';

interface Row {
  id: number;
  name: string;
  price: number;
  color_attr_id: number;
  color_code_id: number;
  size_attr_id: number;
  size_code_id: number;
  sku: string;
}

interface RowTable {
  size: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
  sku: {} | null | undefined;
  price: number;
  id: number;
  imgUrl: string;
  name: string;
  color_attribute_id: number;
  color_code_id: number;
  size_attribute_id: number;
  size_code_id: number;
  items_quantity: number | null;
  is_package: number | null;
  packages_label: string | null;
}

interface Variant {
  size_label: string;
  quantity: number;
  id: number;
  color_attribute_id: number;
  color_code_id: number;
  size_attribute_id: number;
  size_code_id: number;
  type: string
  items_quantity: number
}

function createData(
  size: string,
  parent_id: number,
  sku: string,
  quantity: number,
  price: string,
  id: number,
  color_attribute_id: number,
  color_code_id: number,
  size_attribute_id: number,
  size_code_id: number,
  type: string,
  items_quantity: number,
  is_package: number,
  packages_label: string,
  size_sort_order: number
) {
  return {
    size, parent_id, sku, quantity, price, id, color_attribute_id,
    color_code_id, size_attribute_id, size_code_id, type, items_quantity, is_package, packages_label, size_sort_order
  }
}

function createVariant(
  id: number,
  imgUrl: string,
  name: string,
  price: number,
  qty: number,
  color_attribute_id: number,
  color_code_id: number,
  size_attribute_id: number,
  size_code_id: number,
  sku: string,
  size_sort_order: number
) {
  return { id, imgUrl, name, price, qty, color_attribute_id, color_code_id, size_attribute_id, size_code_id, sku, size_sort_order };
}

function navigateBack() {

  //console.log(window.history);
  if (window.history.length > 1) {
    //window.history.back();
    window.history.go(-1);
  } else {
    window.location.href = "/"
  }

}

const BasicTable: React.memo = ({
  product,
  currencySymbol,
  handleCartAmountChange,
  setButtonClicks,
  color,
  setColor,
  auth,
  setKey,
  loadProgress,
  setLoadProgress,
  setOpen,
  group,
  setGroup,
  setInitialPrice,
  setDiscountPrice,
  updText,
  setUpdText
}) => {

  const screenWidth: any = useWindowSize()
  const { state, fixPrice, setUpdateQ, updateQ } = useAppContext()
  const [variantItems, setVariantItems] = useState([])
  const [showQty, setShowQty] = useState(false)
  const [cartList, setCartList] = useState([])
  const [springs, api] = useSpring(() => ({
    from: { x: 0 },
  }))

  const { t } = useTranslation()

  const step_qty = parseInt(product.qty_step) > 0 ? parseInt(product.qty_step) : 1

  useEffect(() => {
    if (product.variants?.length === 0) {
      setColor(product.variants[0].color_code_id)
    }
  }, [])


  useEffect(() => {

    if (!updateQ) {
      return;
    }

    setShowQty(false)
    //console.log('useef', window.history);
    AxiosService.get('/mini-cart')
      .then((response) => {
        //console.log('axios response mini cart' , response);

        if (!response.data['status']) {
          setShowQty(true)

          return;
        }
        setShowQty(true)
        setCartList(response.data.mini_cart.cart_items)
        setUpdateQ(false)

      }).catch(
        function (error) {
          console.log(error)
          setUpdateQ(false)

        }
      )



  }, [updateQ])

  useEffect(() => {
    if (!color) {
      setShowQty(true)
      return;
    }

    if (updateQ) {
      return;
    }
    setShowQty(false)
    //console.log('useef', window.history);
    AxiosService.get('/mini-cart')
      .then((response) => {
        //console.log('axios response mini cart' , response);

        if (!response.data['status']) {
          setShowQty(true)

          return;
        }
        setShowQty(true)
        setCartList(response.data.mini_cart.cart_items)
      }).catch(
        function (error) {
          console.log(error)
        }
      )
    updateVariantItems()
  }, [color])



  useEffect(() => {
    updateVariantItems()
  }, [cartList])

  var findMinPrice = {};
  var findMinPriceByColorWithDiscount = {};
  var findMinPriceByColorWithNoDiscount = {};

  product?.variants?.map((variant: Variant) => {

    if (!findMinPriceByColorWithNoDiscount[variant?.color_code_id]) {
      findMinPriceByColorWithNoDiscount[variant?.color_code_id] = parseFloat(variant?.initialPrice).toFixed(2)
      findMinPriceByColorWithDiscount[variant?.color_code_id] = parseFloat(variant?.price).toFixed(2)
    }


    if (parseFloat(variant?.initialPrice).toFixed(2) < findMinPriceByColorWithNoDiscount[variant.color_code_id]) {
      findMinPriceByColorWithDiscount[variant.color_code_id] = parseFloat(variant?.price).toFixed(2);
      findMinPriceByColorWithNoDiscount[variant.color_code_id] = parseFloat(variant?.initialPrice).toFixed(2);
    }



    if (!findMinPrice[variant.size_code_id]) {
      findMinPrice[variant.size_code_id] = parseFloat(variant?.price).toFixed(2);
    }
    if (parseFloat(variant?.price).toFixed(2) < findMinPrice[variant.size_code_id]) {
      findMinPrice[variant.size_code_id] = parseFloat(variant?.price).toFixed(2);
    }
  })


  const rows11 = product.variants ? product.variants.map((variant: Variant) => {

    if (color !== null && color == variant.color_code_id) {

      return createData(
        variant.size_label,
        product.id,
        product.sku,
        variant.quantity,
        (parseFloat(variant.price)).toFixed(2),
        variant.id,
        variant.color_attribute_id,
        variant.color_code_id,
        variant.size_attribute_id,
        variant.size_code_id,
        variant.type,
        variant.items_quantity,
        product.is_package,
        variant.packages_label,
        variant.size_sort_order
      )

    } else if (color === null) {

      return createData(
        variant.size_label,
        product.id,
        product.sku,
        variant.quantity,
        findMinPrice[variant.size_code_id],
        variant.id,
        variant.color_attribute_id,
        variant.color_code_id,
        variant.size_attribute_id,
        variant.size_code_id,
        variant.type,
        variant.items_quantity,
        product.is_package,
        variant.packages_label,
        variant.size_sort_order
      )

    } else return {}

  }) : []


  var rows1 = rows11.filter((value: {}) => Object.keys(value).length !== 0)
  rows1 = rows1.sort((a, b) => a.size_sort_order - b.size_sort_order)

  const uniqueRows = rows1.reduce((acc: any[], curr: { size: any, type: string }) => {
    if (!acc.some((item) => item.size === curr.size) || curr.type === 'grouped' || curr.is_package === 1) {
      acc.push(curr)
    }
    return acc
  }, [])

  const uniquePackageRows = Array.from(
    uniqueRows.reduce((map, row) => {
      if (row?.is_package === 1 && typeof row?.packages_label !== 'undefined') {
        if (!map.has(row?.packages_label)) {
          map.set(row?.packages_label, row)
        }
      }
      return map
    }, new Map()).values()
  )

  const iterable = () => {
    if (product?.is_package === 1) {
      return uniquePackageRows
    } else return uniqueRows
  }

  useEffect(() => {

    if (!color) {
      setUpdText(false)
    }

  }, [uniqueRows, uniquePackageRows, color])

  const updateVariantItems = useCallback(() => {

    setUpdText(false)
    setButtonClicks(false)
    if (rows1.length > 0) {
      const newVariantItems = rows1.map((row: Row) => {

        const item = cartList.find((item) => item.product_id === row.id)

        if (item?.qty > 0) {
          setButtonClicks((prev: number) => prev + 1)
          setUpdText(true)
        }
        if (item) {

          return createVariant(
            row.id,
            item.imgUrl,
            row.name,
            row.price,
            item.qty,
            row.color_attribute_id,
            row.color_code_id,
            row.size_attribute_id,
            row.size_code_id,
            row.sku
          )

        } else {
          return null
        }
      }).filter((item: null) => item !== null)

      setVariantItems(newVariantItems)
    }
  }, [cartList])

  const getVariantQty = (id: any) => {

    if (color === null && group === null) {
      return 0
    }

    const item = variantItems.find(item => item.id === id);


    return item ? item.qty : 0
  }

  const handlePlus = useCallback((qty, id, imgUrl, name, price, color_attr_id, color_code_id, size_attr_id, size_code_id, sku) => {

    setButtonClicks((prev: number) => prev + 1)
    const itemIndex = variantItems.findIndex(item => item.id === id)

    if (color === null && group === null) {
      setOpen(true)
      return
    }

    if (itemIndex !== -1) {
      const newItems = [...variantItems];
      newItems[itemIndex].qty += step_qty
      setVariantItems(newItems);
    } else {
      setVariantItems([...variantItems, createVariant(id, imgUrl, name, price, qty, color_attr_id, color_code_id, size_attr_id, size_code_id, sku)]);
    }
  }, [variantItems, color, group])

  const handleMinus = (id: any) => {
    setButtonClicks((prev: number) => prev + 1)

    const itemIndex = variantItems.findIndex(item => item.id === id);

    if (itemIndex !== -1) {
      const newItems = [...variantItems]
      newItems[itemIndex].qty -= step_qty
      if (newItems[itemIndex].qty < 0) {
        newItems[itemIndex].qty = 0
      }
      setVariantItems(newItems)
    }

  }


  useEffect(() => {
    if (updText) {
      api.start({
        from: {
          x: -70,
        },
        to: {
          x: 10,
          opacity: 1,
        },
      })
    } else {
      api.start({
        from: {
          x: 10,
        },
        to: {
          x: -70,
          opacity: 0,
        },
      })
    }

    // console.log('updtex' , updText);

  }, [updText])


  return (
    <>

      <ColorSelect setInitialPrice={setInitialPrice} setDiscountPrice={setDiscountPrice} findMinPriceByColorWithDiscount={findMinPriceByColorWithDiscount} findMinPriceByColorWithNoDiscount={findMinPriceByColorWithNoDiscount} setButtonClicks={setButtonClicks} setKey={setKey} product={product} color={color} setColor={setColor} />
      {/* <GroupSelect setButtonClicks={setButtonClicks} product={product} group={group} setGroup={setGroup} /> */}
      <StyledBasicTable sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', boxShadow: 'unset' }} component={Paper}>
        <Table sx={{ color: 'white' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell >{product.is_package ? t("product.package") : t("product.size")}</TableCell>
              <TableCell align="left">{t("product.sku")}</TableCell>
              {/* {screenWidth > 520 && <TableCell sx={{ color: 'white' }} align="left">Απόθεμα</TableCell>} */}
              {auth && <TableCell align="left">{t("product.price")}</TableCell>}
              {auth && <TableCell align="left"></TableCell>}
            </TableRow>
          </TableHead>

          <TableBody >
            {iterable && iterable().map((row: RowTable, ind: any) => (
              <TableRow
                key={ind}
                sx={{
                  height : 45 ,
                  '&:last-child td, &:last-child th': { border: 0 },
                  ...(row.quantity < 1 && color !== null && { color: '#bbacac' }),
                }}>

                <TableCell component="th" scope="row" >
                  {row.type === 'grouped'
                    ? `${row.items_quantity} ${t("product.pieces")}`
                    : row.is_package === 1
                      ? `${row.packages_label} τεμ.`
                      : row.size}
                </TableCell>

                <TableCell align="left" >{row.sku}</TableCell>
                {auth && <TableCell className='table-price' align="left" >
                  {/**  parseFloat(row.price).toFixed(2) / parseFloat(product?.product_price_discount)  */}
                  {/**  parseFloat(row.price).toFixed(2) / parseFloat(product.package_quantity)  */}

                  {row.is_package === 1 && product.product_price ?
                    `${row.packages_label} x ${fixPrice(parseFloat(row.price).toFixed(2) / parseFloat(row.packages_label))} = ${parseFloat(row.price).toFixed(2)} ${currencySymbol} `
                    :

                    product.package_quantity ?
                      `${product.package_quantity} x ${fixPrice(parseFloat(row.price).toFixed(2) / parseFloat(product.package_quantity))} = ${parseFloat(row.price).toFixed(2)} ${currencySymbol} `

                      : `${parseFloat(row.price).toFixed(2)}${currencySymbol}`
                  }
                </TableCell>}

                {auth && <TableCell >
                  {row.quantity > 0 && color !== null &&
                    <StyledQuantityButtons justifyContent={'flex-end'} pr='5px'>
                      <Button
                        variant="outlined"
                        className='quantity-button'
                        size="none"
                        color="#C7DEEC"
                        onClick={() => handleMinus(row.id)}
                      >
                        <Icon variant="small">minus</Icon>
                      </Button>

                      <Typography className='quantity' >

                        {showQty ?
                          getVariantQty(row.id)
                          : <Box width={screenWidth > 900 ? 30 : 15} margin='auto'>
                            <Skeleton sx={{ bgcolor: 'rgb(35 33 33 / 35%);', borderRadius: '5px' }} />
                          </Box>}

                      </Typography>


                      <Button
                        variant="outlined"
                        className='quantity-button'
                        size="none"
                        color="#C7DEEC"
                        onClick={() => handlePlus(step_qty, row.id, row.imgUrl, row.name, row.price, row.color_attribute_id, row.color_code_id, row.size_attribute_id, row.size_code_id, row.sku)}

                      >
                        <Icon variant="small">plus</Icon>
                      </Button>
                    </StyledQuantityButtons>}

                  {color === null &&
                    <StyledQuantityButtons justifyContent={'flex-end'} pr='5px'>
                      <Button
                        variant="outlined"
                        color="#C7DEEC"
                        padding="1px"
                        size="none"
                        className='quantity-button'

                        onClick={() => handleMinus(row.id)}
                      >
                        <Icon variant="small">minus</Icon>
                      </Button>

                      <Typography className='quantity' >

                        {showQty ?
                          getVariantQty(row.id)
                          : <Box width={screenWidth > 900 ? 30 : 15} margin='auto'>
                            <Skeleton sx={{ bgcolor: 'rgb(35 33 33 / 35%);', borderRadius: '5px' }} />
                          </Box>}

                      </Typography>
                      <Button
                        variant="outlined"
                        color="#C7DEEC"
                        padding="1px"
                        size="none"
                        className='quantity-button'

                        onClick={() => handlePlus(1, row.id, row.imgUrl, row.name, row.price, row.color_attribute_id, row.color_code_id, row.size_attribute_id, row.size_code_id, row.sku)}

                      >
                        <Icon variant="small">plus</Icon>
                      </Button>
                    </StyledQuantityButtons>}

                </TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledBasicTable>

      <StyledAddButton
        mb="36px"
        onClick={handleCartAmountChange(variantItems, setVariantItems)}
        className='add-to-cart-button'
      >
        {!loadProgress
          ?
          <>
            <Box className='test'>{t("buttons.addtocart")}</Box>
            < Icon variant="medium" ml='10px' >
              bag
            </Icon>
            <animated.svg style={{ ...springs }}
              //className={updText ? 'end' : 'start'}
              width={25} focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="SuccessOutlinedIcon">
              <path fill='#2e7d32' d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z">
              </path>
            </animated.svg>

          </>

          :
          <Box width='100%' position={'relative'} color='royalblue'>
            <CircularProgress size='30px' color='inherit' />
          </Box>
        }

      </StyledAddButton>
      <FlexBox width='100%' justifyContent='space-between'>
        <FlexBox flexDirection='column'>
          <Box width='100%'>
            {!auth &&
              <StyledLink href='/customer/login'>
                <Span>{t("buttons.seeprices")}</Span><Image width='30px' src='/themes/default/assets/images/user-icon.png' alt="cover image"/>
              </StyledLink>
            }
          </Box>

          {screenWidth > 768 && product.size_chart && <SizeGuide sizeChart={product.size_chart} />}

        </FlexBox>
        {/*<Button onClick={() => navigateBack()} className='back-button'> <ChevronLeftIcon /> {t("buttons.back")}</Button>*/}
        <Button onClick={() => history.back()} className='back-button'> <ChevronLeftIcon /> {t("buttons.back")}</Button>
      </FlexBox>

    </>
  );
}

export default BasicTable