import styled from "styled-components";
import { layoutConstant } from "../../utils/constants";
import { getTheme } from "../../utils/utils";

const StyledHeader = styled.header`
  position: relative;
  z-index: 1;
  height: 133px;
  background : transparent;
  width : 80%;
  margin : auto ;
  top : 1rem;
  background-color: rgba(0,0,0,.5);
  backdrop-filter : blur(3px);
  border-radius : 20px;

  .close-button{
    background : red;
  }


  .flex-33{
    flex-basis:33.3%;
  }
  
  .menuIconMenu div{
    margin-left:10px;
    
  }

  .header-left-mobile{
    gap : 1.5rem;
  }

  button:hover {
    background-color : transparent;
  }

  .logo {
    img {
      display: block;
      width : 210px;
    }
  }
  .icon-holder {
    span {
      font-size: 12px;
      line-height: 1;
      margin-bottom: 4px;
    }
    h4 {
      margin: 0px;
      font-size: 14px;
      line-height: 1;
      font-weight: 600;
    }
    div {
      margin-left: 6px;
    }
  }

  .user {
    cursor: pointer;
  }

  .menu-item-holder{
    background-color : rgba(0, 0, 0, 0.9) !important;
  }

  .logged-in > div {
    width : 30px;
    
    
  }

  .price-flair{
    position: absolute;
    top: 100%;
    left: -5%;
    white-space : nowrap;
    font-size : 12px;
    }


    

  @media only screen and ( min-width : 1980px) {

    

    .close-button{
      width:2vw;
      height:1vw;
      svg{
        width:1vw;
        height:1vw;
      }
    }

    .price-flair{
      font-size : 0.5vw;
    }

    .logo img {
      width : 8vw;
    }

    

    .info-header{
      flex-basis:33.3%;
      h3{
        font-size : 0.8vw;
      }

      svg{
        width:1vw;
        height:auto;
      }

    }

    .svg-header > div {
      width : 1vw;
      height : 1vw;
    }

    .quantity-cart-chip span{
     font-size : 0.6vw;
     padding : 0px 2px 0px 2px;
     
    }

    

    .menuIcon div{
      width:1.2vw;
      height:1.2vw;
    }

    .menuIconUser div{
      width:2vw;
      height:2vw;
    }

    .menuIconMenu div{
      width:1.5vw;
      height:1.5vw;
    }

    .logged-in > div {
      width:1.4vw;
      height:1.4vw;
    }

    .menu-item-holder > div{
      font-size : 1vw;
    }

    .tiny-2k{
      font-size : 0.6vw;
    }
    
  }

  @media only screen and (max-width: 900px) {
    height: ${layoutConstant.mobileHeaderHeight};
    width : 98%; 
    top : 0.1rem;
    
    .logo img{
      width : 14vw ;
      margin:auto;
    }
    
    .info-header{

      span , h3{
        font-size : 12px;
      }
    }

    .menuIcon div{
      width:20px;
      height:20px;
    }

    .menuIconUser div{
      width:32px;
      height:32px;
    }

    .menuIconMenu div{
      width:25px;
      height:25px;
      margin-left:5px;
    }

    .logged-in > div {
      width : 22px;
      height : 22px;
    }
    
    .bulk {
      font-size : 12px;
    }
    
    
  }

  .header-right{
    gap : 1.5rem;
  }

  @media only screen and (max-width: 500px ){
    .logo img{
      width : 100px ;
      margin:auto;
    }

    .price-flair{
      font-size : 10px;
      
    }

    svg{
      width : 24px;
    }

    .header-right{
      gap : 1;
    }
  
  }

 
`;

export default StyledHeader;
