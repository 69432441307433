import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    //.use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'EL',
       // debug: true,
        resources: {
            EL: {
                translation: {
                    bulk: "Χονδρική Πώληση - B2B",
                    welcome: 'Καλώς ήρθες',
                    search: 'Αναζήτηση',
                    color: 'Χρώμα :',
                    size: 'Μέγεθος :',
                    total: 'Σύνολο',
                    price: 'Τιμή',
                    quantity: 'Ποσότητα',
                    subtotal: 'Υποσύνολο',
                    vat: 'Φ.Π.Α.',
                    discount: 'Έκπτωση',
                    ordersummary: 'Περίληψη Παραγγελίας',
                    yes: 'Ναι',
                    no: 'Όχι',
                    ok: 'Εντάξει',
                    topic : 'Θέμα' ,
                    noresultssearch: 'Δεν υπάρχουν αποτελέσματα για τις λέξεις που αναζητήσατε.',
                    message : 'Μήνυμα' ,
                    confirmMessage : 'Είστε σίγουροι;' ,
                    buttons: {
                        addtocart: 'Προσθήκη στο Καλάθι',
                        back: 'Πίσω',
                        seeprices: 'Συνδεθείτε για να δείτε τις τιμές',
                        all: 'Όλα',
                        viewcart: "Προβολή Καλαθιού",
                        completeorder: 'Ολοκλήρωση Παραγγελίας',
                        orderentry: 'Καταχώρηση Παραγγελίας',
                        save: 'Αποθήκευση',
                        backtostore: 'Επιστροφή στο κατάστημα' ,
                        send : 'Αποστολή'
                    },
                    product: {
                        vat: "Στην τιμή δεν συμπεριλαμβάνεται Φ.Π.Α. 24%",
                        discount: "Ανήκετε σε κατηγορίας έκπτωσης πελατών από το SOMA",
                        colorselect: "Επιλογή Χρώματος :",
                        package: "Πακέτο",
                        size: "Μέγεθος",
                        sku: "Κωδ.Προϊόντος (SKU)",
                        price: "Τιμή",
                        pieces: "τεμ.",
                        details: "Λεπτομέρειες",
                        composition: 'Σύνθεση',
                        sizeguide: "Οδηγός Μεγεθών",
                        available: 'Διαθέσιμο σε'
                    },
                    minicart: {
                        empty: 'Το καλάθι σας είναι άδειο. Ξεκινήστε τις αγορές.'
                    },
                    filters: {
                        title: 'Φίλτρα',
                        activefilters: 'Ενεργά Φίλτρα',
                        view: "Προβολή",
                        sort: "Ταξινόμηση ανά : ",
                        price: 'Τιμή' ,
                        new : 'Νεότερο' ,
                        old : 'Πιο παλιό' ,
                        cheap : 'Φθηνότερο' ,
                        expensive : 'Ακριβότερο'
                    },
                    footer: {
                        contact: "Επικοινωνία",
                        address: "Σέλερο, Ξάνθης 67150, Ελλάδα",
                        account: "Λογαριασμός",
                        connect: "Σύνδεση",
                        myaccount: "Ο Λογαριασμός μου",
                        cart: 'Καλάθι Αγορών',
                        shipping: 'Τρόποι Αποστολής',
                        payment: 'Τρόποι Πληρωμής',
                        privacy: 'Πολιτική απορρήτου',
                        return: 'Πολιτική επιστροφών',
                        websitescreate: 'Κατασκευή Ιστοσελίδων',
                        company: 'Η Εταιρεία'

                    },
                    menu: {
                        home: 'Αρχική',
                        contact: 'Επικοινωνία',
                        profile: 'Προφίλ',
                        addresses: 'Διευθύνσεις',
                        orders: 'Παραγγελίες',
                        changepassword: 'Αλλαγή Κωδικού',
                        logout: 'Αποσύνδεση'
                    },
                    cartpage: {
                        cart: 'Καλάθι',
                        emptycart: 'Άδειασμα Καλαθιού',
                        type: 'Είδος',
                        totalprice: 'Τελική Τιμή'
                    },
                    checkoutpage: {
                        checkout:"Ολοκλήρωση Παραγγελίας",
                        review: 'Σύνοψη Παραγγελίας',
                        productsincart: 'προϊόντα στο καλάθι',
                        name: 'Όνομα',
                        surname: "Επίθετο",
                        shippingdetails: 'Στοιχεία Αποστολής',
                        country: "Χώρα",
                        address: 'Διεύθυνση',
                        region: 'Περιφέρεια',
                        city: 'Πόλη',
                        postalcode: 'Ταχυδρομικός Κώδικας',
                        cellphone: 'Κινητό Τηλέφωνο',
                        comment: 'Σχόλια',
                        shippingmethod: 'Μέθοδος Αποστολής',
                        paymentmethod: 'Μέθοδος Πληρωμής',
                        shipping: 'Μεταφορικά',
                        contactwithyou: 'Θα επικοινωνήσουμε μαζί σας'
                    },
                    login: {
                        login: 'Είσοδος',
                        register: 'Εγγραφή',
                        nomember: 'Δεν είστε ήδη μέλος;',
                        rememberme: 'Να με θυμάσαι',
                        forgotpassword: 'Ξέχασα τον κωδικό μου',
                        password: 'Κωδικός',
                        errormessage: "Λάθος κωδικός ή όνομα χρήστη"
                    },
                    signup: {
                        create: 'Δημιουργία Λογαριασμού',
                        alreadymember: 'Είστε ήδη μέλος;',
                        afm: 'Α.Φ.Μ.',
                        company: 'Επωνυμία',
                        streetnumber: 'Αριθμός οδού',
                        cellphone: 'Κινητό',
                        phone: 'Σταθερό',
                        taxoffice: 'ΔΟΥ',
                        agree: 'Συμφωνώ με τους',
                        terms: 'Όρους Χρήσης και Προϋποθέσεων',
                        details: 'Στοιχεία Πελάτη'
                    },
                    thankyou: {
                        thankyou: 'Ευχαριστούμε για την παραγγελία',
                        message: 'Θα λάβετε ένα email επιβεβαίωσης παραγγελίας με λεπτομέρειες.'
                    },
                    resetpassword: {
                        reset: 'Επαναφορά κωδικού πρόσβασης',
                        message: 'Παρακαλώ εισάγετε το email σας παρακάτω για να λάβετε έναν σύνδεσμο επαναφοράς του κωδικού πρόσβασης.',
                    },
                    myaccount: {
                        myaccount: "Ο λογαριασμός μου",
                        edit: 'Επεξεργασία Προφίλ',
                        phone: 'Τηλέφωνο',
                        myaddresses: 'Οι διευθύνσεις μου',
                        newaddress: 'Νέα Διεύθυνση',
                        myorders: 'Οι παραγγελίες μου',
                        date: 'Ημερομηνία',
                        status: 'Κατάσταση Παραγγελίας',
                        totalorder: ' Σύνολο Παραγγελίας',
                        reorder: 'Επανεκτέλεση',
                        reordermessage: 'Θέλετε να προσθέσετε τα προϊόντα της παραγγελίας στο καλάθι;',
                        completed: "Ολοκληρωμένη",
                        pending: 'Σε αναμονή',
                        orderdetails: 'Λεπτομέρειες Παραγγελίας',
                        back: 'Πίσω στις παραγγελίες',
                        orderdate: 'Ημερομηνία Παραγγελίας',
                        delivered: 'Παραδόθηκε στις ',
                        changepassword: 'Αλλαγή Κωδικού',
                        password: 'Τωρινός Κωδικός',
                        newpassword: 'Νέος Κωδικός',
                        confirm: 'Eπιβεβαίωση νέου Κωδικού',
                        backtoprofile: 'Πίσω στο προφίλ',
                        birthdate: "Ημ. Γέννησης",
                        deleteAddress: "Είσαι σίγουρος ότι θέλεις θέλεις να διαγράψεις τη διεύθυνση σου;"
                    },
                    snackbar: {
                        add: 'Eπιτυχής προσθήκη στο',
                        cart1: 'καλάθι!',
                        update: 'Eπιτυχής ενημέρωση',
                        cart2: 'καλαθιού!',
                        remove: 'Eπιτυχής αφαίρεση από το',
                        wrong: 'Κάτι πήγε στραβά!',
                        tryagain: 'Προσπαθήστε ξανά.',
                        succupdate: 'Επιτυχής ενημέρωση!',
                        succreate: 'Επιτυχής δημιουργία!',
                        register: 'Σας ευχαριστούμε για το αίτημα εγγραφής στο κατάστημά μας.',
                        vat: 'Το ΑΦΜ δεν αντιστοιχεί σε επιχείρηση.',
                        contact: 'Ευχαριστούμε που επικοινωνήσατε με το SOMA !',
                        emptycart: 'Επιτυχής άδειασμα καλαθιού !',
                    },
                    dialog: {
                        choose: 'Παρακαλούμε επιλέξτε ένα',
                        choose1: ' Παρακαλούμε επιλέξτε',
                        beforeadd: 'πριν προσθέσετε το προϊόν στο καλάθι σας.',
                        addlogin: 'για να μπορείτε να προσθέσετε προϊόντα στο καλάθι σας.',
                        beforeorder: 'πριν ολοκληρώσετε την παραγγελίας σας.',
                        please: 'Παρακαλούμε',
                        login: 'συνδεθείτε',
                        register: 'Εγγραφή',
                        login1: 'Σύνδεση',
                        color: 'χρώμα',
                        quantity: 'ποσότητα',
                        shippingmethod: 'μέθοδο αποστολής',
                        paymentmethod: 'μέθοδο πληρωμής',
                        delete: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το προϊόν από το καλάθι;',
                        emptycart: 'Είστε σίγουροι ότι θέλετε να αδειάσετε από το καλάθι?',
                        addallitems: 'Θέλετε να προσθέσετε τα προϊόντα της παραγγελίας στο καλάθι;'
                    },
                    application: {
                        message: 'Οδηγίες για αποθήκευση του eshop soma.com.gr ως εφαρμογή στο κινητό σας.',
                        ios1: 'Πρώτα επιλέγεται το κουμπί που βρίσκεται κεντρικά στο κάτω μέρος της οθόνης ',
                        ios2: 'Επιλέγουμε από το αναδυόμενο menu την επιλογή Add to Home Screen ή Προσθήκη στην αρχική οθόνη',
                        ios3: "Επιλέγουμε πάνω δεξιά Add ή Προσθήκη ",
                        android1: "Πρώτα επιλέγεται το κουμπί που βρίσκεται πάνω δεξιά με τις τρεις τελείες ",
                        android2: 'Επιλέγουμε από το αναδυόμενο menu την επιλογή Add to Home Screen ή Προσθήκη στην αρχική οθόνη ',
                        android3: 'Επιλέγουμε Εγκατάσταση ή Install '
                    },

                    validations: {
                        name: "To όνομα είναι απαραίτητο",
                        surname: "To επίθετο είναι απαραίτητο",
                        email: "Το email είναι απαραίτητο",
                        mobile: "Το κινητό είναι απαραίτητο",
                        subject: "To θέμα είναι απαραίτητο",
                        message: "Το μήνυμα είναι απαραίτητο",


                    },

                }
            },
            EN: {
                translation: {
                    bulk: "Wholesale - B2B",
                    welcome: 'Welcome',
                    search: 'Search',
                    color: 'Color :',
                    size: 'Size :',
                    total: 'Total',
                    price: 'Price',
                    quantity: 'Quantity',
                    subtotal: 'Subtotal',
                    vat: 'VAT',
                    discount: 'Discount',
                    ordersummary: 'Order Summary',
                    yes: 'Yes',
                    no: 'No',
                    ok: 'Ok',
                    noresultssearch: 'No results for the words you searched for.',
                    topic : 'Topic',
                    message : 'Message' ,
                    confirmMessage : 'Are you sure?' ,
                    buttons: {
                        addtocart: 'Add to Cart',
                        back: 'Back',
                        seeprices: "Log in to see the prices",
                        all: 'See All',
                        viewcart: "View Cart",
                        completeorder: 'Complete Order',
                        orderentry: 'Complete Order',
                        save: 'Save',
                        backtostore: 'Back to store' ,
                        send : 'Send'
                    },
                    product: {
                        vat: "The price does not include VAT 24%",
                        discount: "You belong to a customer discount category from SOMA",
                        colorselect: "Select Color :",
                        package: "Package",
                        size: "Size",
                        sku: "SKU",
                        price: "Price",
                        pieces: "pcs",
                        details: "Details",
                        composition: "Composition",
                        sizeguide: "Size Guide",
                        available: 'Available in'
                    },
                    minicart: {
                        empty: 'Your basket is empty. Start shopping.'
                    },
                    filters: {
                        title: 'Filters',
                        activefilters: 'Active Filters',
                        view: "View",
                        sort: "Sort By : ",
                        price: 'Price' ,
                        new : 'Newest' ,
                        old : 'Old' ,
                        cheap : 'Lowest Price' ,
                        expensive : 'Highest Price'
                    },
                    footer: {
                        contact: "Contact",
                        address: "Selero, Xanthi 67150, Greece",
                        account: "Account",
                        connect: "Connect",
                        myaccount: "My Account",
                        cart: "Shopping Cart",
                        shipping: 'Shipping',
                        payment: "Payment",
                        privacy: "Privacy Policy",
                        return: "Return Policy",
                        websitescreate: "Websites Creation",
                        company: "Company"
                    },
                    menu: {
                        home: 'Home',
                        contact: 'Contact',
                        profile: 'Profile',
                        addresses: 'Addresses',
                        orders: 'Orders',
                        changepassword: 'Change Password',
                        logout: 'Logout'
                    },
                    cartpage: {
                        cart: 'Cart',
                        emptycart: 'Empty Cart',
                        type: 'Product',
                        totalprice: 'Total Price'
                    },
                    checkoutpage: {
                        checkout:"Check out",
                        review: 'Order Summary',
                        productsincart: 'products in cart',
                        name: 'Name',
                        surname: "Surname",
                        shippingdetails: 'Shipping Details',
                        country: "Country",
                        address: 'Address',
                        region: 'Region',
                        city: 'City',
                        postalcode: 'Postal Code',
                        cellphone: 'Phone Number',
                        comment: 'Comments',
                        shippingmethod: 'Shipping Method',
                        paymentmethod: 'Payment Method',
                        shipping: 'Shipping',
                        contactwithyou: 'We will contact you'
                    },
                    login: {
                        login: 'Login',
                        register: 'Register',
                        nomember: 'Not a member already?',
                        rememberme: 'Remember me',
                        forgotpassword: 'I forgot my password',
                        password: 'Password',
                        errormessage: "Wrong password or username"
                    },
                    signup: {
                        create: 'Create Account',
                        alreadymember: 'Already a member?',
                        afm: 'VAT ID',
                        company: 'Company Name',
                        streetnumber: 'Street Number',
                        cellphone: 'Cell Phone',
                        phone: 'Phone',
                        taxoffice: 'Tax Office',
                        agree: 'I agree with the',
                        terms: 'Terms of Use and Conditions',
                        details: 'Customer Details'
                    },
                    thankyou: {
                        thankyou: 'Thank you for the order',
                        message: 'You will receive an order confirmation email with details.'
                    },
                    resetpassword: {
                        reset: 'Reset Password',
                        message: 'Please enter your email below to receive a password reset link.',
                    },

                    myaccount: {
                        myaccount: "My account",
                        edit: 'Edit Profile',
                        phone: 'Phone',
                        myaddresses: 'My Addresses',
                        newaddress: 'New Address',
                        myorders: 'My Orders',
                        date: 'Date',
                        status: 'Order Status',
                        totalorder: ' Order Total',
                        reorder: 'Reorder',
                        reordermessage: 'Do you want to add the products of the order to the cart?',
                        completed: "Completed",
                        pending: 'Pending',
                        orderdetails: 'Order Details',
                        back: 'Back to orders',
                        orderdate: 'Order Date',
                        delivered: 'Delivered at',
                        changepassword: 'Change Password',
                        password: 'Old Password',
                        newpassword: 'New Password',
                        confirm: 'Confirm new Password',
                        backtoprofile: 'Back to profile',
                        birthdate: "Birth Date",
                        deleteAddress: "Do you want to delete the address;"
                    },
                    snackbar: {
                        add: 'Added to',
                        cart1: 'cart!',
                        update: 'Successful update of the',
                        cart2: 'cart!',
                        remove: 'Successful removal from the',
                        wrong: 'Something went wrong!',
                        tryagain: 'Try again.',
                        succupdate: 'Successful update!',
                        succreate: 'Successful creation!',
                        register: 'Thank you for your request to subscribe to our store.',
                        vat: 'The VAT ID does not correspond to a business.',
                        contact: 'Thank you for contacting SOMA !',
                        emptycart: 'Successful basket emptying !',
                    },
                    dialog: {
                        choose: 'Please choose a',
                        choose1: ' Please choose',
                        beforeadd: 'before adding the product in your cart.',
                        beforeorder: 'before you complete your order.',
                        addlogin: 'so you can add products to your basket.',
                        please: 'Please',
                        login: 'login',
                        register: 'Register',
                        login1: 'Login',
                        color: 'color',
                        quantity: 'quantity',
                        shippingmethod: 'shipping method',
                        paymentmethod: 'payment method',
                        delete: 'Are you sure you want to delete this product from your cart?',
                        emptycart: 'Are you sure you want to empty the cart?',
                        addallitems: 'Do you want to add the products of the order to the cart?'
                    },
                    application: {
                        message: 'Instructions for installing eshop soma.com.gr as an application on your mobile phone.',
                        ios1: 'First select the button located centrally at the bottom of the screen',
                        ios2: 'Select the Add to Home Screen option from the pop-up menu',
                        ios3: "Select Add at the top right",
                        android1: "First select the button on the top right with the three dots",
                        android2: 'Select the Add to Home Screen option from the pop-up menu',
                        android3: 'Select Install'
                    },

                    validations: {
                        name: "The name is required",
                        surname: "The surname is required",
                        email: "The email is required",
                        mobile: "The mobile is required",
                        subject: "The subject is required",
                        message: "The message is required",
                    },

                }
            },
        }

    })


export default i18n

export const lngs = {
    EL: { nativeName: 'ΕΛ' },
    EN: { nativeName: 'EN' },

}